/*==============================================================================
// File:        _tour-search.scss
// Package:     Tour Search UI - Vue3 - RezKit
// Synopsis:    Tour search style overrides - SASS
//============================================================================*/
.rk-results--fluid {
  --rkts-justify-content: center;
  --rkts-flex-flow: row wrap;
  --rkts-align-items: start;
}

.rk-select {
  background-image: url("/templates/zenbasej4template/images/sys-dropdown.svg");
  border: 1px solid var(--rk-border-colour);
}

.rkts-sort-by {
  --rkts-select-message: "Sort by";

  font-family: var(--rk-secondary-font);

  &:before {
    font-weight: var(--rk-font-normal);
    top: 5px;
  }

  @include element("drop-down") {
    --rkts-message-indent: 75px;

    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--rk-font-bold);
  }
}

.rkts-search-box {
  background: var(--rk-base-grey-lightest);
  margin-bottom: 0;

  .rk-input--search-box.layout-primary {
    font-size: var(--rk-font-size-lg);
    padding: 0 80px;
    font-family: var(--rk-secondary-font-semibold);

    @media (max-width: 1400px) {
      font-size: var(--rk-font-size-md) !important;
      padding: 0 60px;
    }

    @media (max-width: 991px) {
      font-size: var(--rk-font-size-sm) !important;
      padding: 0 60px;
    }

    @media (max-width: 767px) {
      font-size: var(--rk-font-size-sm);
      padding: 0 20px;
    }
  }

  &:before {
    font-size: var(--rk-icon-size-sm);
    content: "\e81d";
  }

  @media (max-width: 767px) {
    &:before {
      display: none;
    }
  }

  @include modifier("primary") {
    background: var(--rk-base-grey-lighter);
    margin: 0 auto;

    @media (max-width: 767px) {
      background: var(--rk-base-white);
      margin: 0 auto;
      border: 1px solid var(--rk-base-grey-light);
    }

    &:before {
      content: none;
    }
  }

  .rk-input--search-box {
    border: 1px solid var(--rk-base-grey-lightest);
    color: var(--rk-text);

    &::-webkit-input-placeholder {
      overflow: visible;
    }

    &:-ms-input-placeholder {
      overflow: visible;
    }

    &::placeholder {
      overflow: visible;
    }

    &:not(.layout-primary) {
      padding-left: var(--rk-p-xxl);
    }

    @include adjoin("layout-primary") {
      --rkts-min-height: 74px;

      font-size: var(--rk-font-size-lg);

      @include media-breakpoint-down(md) {
        font-size: var(--rk-font-size-sm);
        min-height: 64px;
      }
    }
  }

  @include element("submit") {
    padding: var(--rk-p-sm) 12px;

    @media (max-width: 767px) {
      background: var(--rk-base-grey-lightest);
      color: var(--rk-primary);
    }

    .fontello {
      line-height: unset;
      padding: 20px;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .icon-sys-search-1:before {
      content: "Search";
      font-family: var(--rk-secondary-font-semibold);
      text-transform: uppercase;
      font-size: 14px;
    }

    @include media-breakpoint-down(md) {
      font-size: 30px;
    }
  }
}

.rkts-results-count {
  font-family: var(--rk-secondary-font);
}

.rk-list--suggestion {
  border: none;

  li:not(.title) {
    margin: 0 var(--rk-p-sm) var(--rk-p-sm) 0;
    display: inline-block;
    line-height: 37px;

    .suggestion-label {
      border: 2px solid var(--rk-border-colour);
      padding: var(--rk-p-sm) var(--rk-p-df);
      color: var(--rk-base-black);

      &:hover,
      &:focus {
        border-color: var(--rk-primary);
        color: var(--rk-base-black);
        font-weight: normal;
      }
    }
  }
}

.rk-filters--horizontal,
.rk-filters {
  --rkts-justify-content: start;
  --rkts-flex-flow: row wrap;

  .dropdown-menu {
    --bs-dropdown-border-radius: 0;

    @include insert-shadow(0, 7px, 14px, 0, $shadow-light);

    border: 1px solid var(--rk-border-colour);
    border-top: none;
    top: 16px !important;
  }
}

.rk-filters__facet {
  padding: var(--rk-p-df) var(--rk-p-df) var(--rk-p-lg);
  font-size: var(--rk-font-size-df);
  min-width: 300px;

  &:not(.allow-overflow) {
    max-height: 55vh;
    overflow: auto;
  }
}

.rkts-collapsible-list__content {
  position: relative;
  padding-left: 5px !important;
}

.rkts-collapsible-list__content:before {
  background: var(--rk-base-grey-lighter);
  position: absolute;
  display: block;
  content: "";
  height: 100%;
  width: 6px;
  left: 0;
  top: 0;
}

.rkts-collapsible-list__title {
  padding: 10px;
  font-weight: 700;
  font-size: 17px;
  font-family: var(--rk-primary-font-bold);
  border-left: 6px solid var(--rk-base-grey-lighter);
  position: relative;

  &.drop-down:after {
    content: "\e804";
    font-family: fontello;
    font-size: 17px;
    position: absolute;
    right: 10px;
    color: var(--rk-base-grey);
    top: 10px;
  }
}

.rkts-collapsible-list.open {
  padding: unset;
}

.rkts-refine-tag {
  margin: 0 10px 0 0;
  padding: 13px;
  line-height: 1em;
  background: var(--rk-base-white);
  border: 1px solid var(--rk-base-grey-lighter);
  transition: all .2s ease-in-out;
  display: flex;
  align-items: center;

  &:hover {
    background: var(--rk-base-grey-lighter);
  }

  @include element("text") {
    font-weight: bold;
    font-size: 13px;
    color: var(--rk-primary);
  }

  @include element("icon") {
    &:before {
      font-size: 20px;
      margin-left: 5px;
      color: var(--rk-primary);
      content: "\e81c";
      font-family: fontello;
    }
  }
}

.rkts-list-checkbox {
  margin-left: 10px;

  @include element("label") {
    margin: 3px 0 0;
    font-family: var(--rk-secondary-font);
  }

  @include element("choice") {
    --rkts-inner-size: 10px;

    border: 1px solid $border-colour-dark;
    border-radius: 0;

    &:before {
      top: 4px !important;
      left: 4px !important;
    }
  }
}

.rkts-collapsible-list {
  --rkts-collapse-icon: "\f107";

  margin: 0;

  @include element("title") {
    .rkts-list-checkbox__container,
    .rkts-list-checkbox {
      margin: 0;
    }

    &:after {
      color: var(--rk-primary);
    }
  }

  @include element("content") {
    padding-left: var(--rk-p-lg);
  }
}

.rkts-descriptive-list {
  @include element("title") {
    font-weight: var(--rk-font-semi-bolder);

    .rkts-list-checkbox__container,
    .rkts-list-checkbox {
      margin: 0;
    }
  }

  @include element("line-break") {
    border-top: 1px solid var(--rk-border-colour);
    opacity: 1;
  }
}

.rkts-range-slider {
  max-width: 400px;
}

.rk-results {
  column-gap: 15px;

  @include modifier("fluid") {
    @include media-breakpoint-down(lg) {
      --rkts-justify-content: center;
    }
  }
}

.rk-btn--clear {
  margin-top: 10px !important;
  font-weight: 400;

  --rkts-align-items: end;

  font-family: var(--rk-secondary-font);
  padding: 10px !important;
  display: flex;
  align-items: center;

  &:hover {
    .icon-sys-close:before {
      color: var(--rk-base-white);
    }
  }

  .icon-sys-close:before {
    transition: all .2s ease-in-out;
    font-size: var(--rk-font-size-xl);
    content: "\e81c" !important;
  }

  @include adjoin("outlined") {
    @extend %zen-btn--outlined-primary-clear;

    margin-top: 0 !important;
    padding: var(--rk-p-df) var(--rk-p-df);
    text-transform: uppercase;
    line-height: 1em;

    .icon-sys-close:before {
      content: none;
    }
  }
}

.rkts-list-pagination .Pagination {
  .Page {
    margin: 0;
    font-weight: var(--rk-font-bold);
    color: var(--rk-base-black);
    border: none;
    padding: 10px;

    @include adjoin("Page-active") {
      font-weight: var(--rk-font-bold);
    }
  }

  .PaginationControl {
    border: 1px solid var(--rk-base-grey-light);
    justify-content: center;
    align-items: center;
    padding: 2px;

    @include media-breakpoint-down(lg) {
      svg {
        fill: var(--rk-base-grey-dark);
      }
    }
  }
}

// Style Overrides Specific to MRA

#tour-search-input:before {
  content: "\e811";
  font-family: fontello;
  font-size: 30px;
  position: absolute;
  z-index: 100;
  top: 15px;
  left: 20px;

  @media (max-width: 767px) {
    display: none;
  }
}

#tour-search-input {
  position: relative;
}

#tour-search-input-modal:before {
  content: "\e811";
  font-family: fontello;
  font-size: 30px;
  position: absolute;
  z-index: 100;
  top: 15px;
  left: 20px;

  @media (max-width: 767px) {
    display: none;
  }
}

#tour-search-input-modal {
  position: relative;
}

.tour-search-wishlist {
  .rk-results {
    justify-content: start;
  }
}
