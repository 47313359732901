.zen-alert {
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid transparent;
  font-family: var(--rk-secondary-font);
  border-radius: 0;

  p {
    margin-bottom: 0;
  }

  @include modifier("primary") {
    color: var(--rk-base-white);
    background-color: var(--rk-primary);
    border-color: var(--rk-primary-dark);
  }

  @include modifier("secondary") {
    color: var(--rk-primary);
    background-color: var(--rk-secondary);
    border-color: var(--rk-secondary-dark);
  }

  @include modifier("success") {
    color: var(--rk-base-white);
    background-color: var(--rk-success);
    border-color: var(--rk-success-dark);
  }

  @include modifier("danger") {
    color: var(--rk-base-white);
    background-color: var(--rk-danger);
    border-color: var(--rk-danger-dark);
  }

  @include modifier("warning") {
    color: var(--rk-base-white);
    background-color: var(--rk-primary);
    border-color: var(--rk-primary-dark);
  }

  @include modifier("info") {
    color: var(--rk-base-white);
    background-color: var(--rk-info);
    border-color: var(--rk-info-info);
  }

  @include modifier("light") {
    color: var(--rk-base-black);
    background-color: var(--rk-light);
    border-color: var(--rk-secondary);
  }

  @include modifier("dark") {
    color: var(--rk-base-white);
    background-color: var(--rk-dark);
    border-color: var(--rk-dark);
  }
}

%zen-alert {
  @extend .zen-alert;
}

%zen-alert--primary {
  @extend .zen-alert--primary;
}

%zen-alert--secondary {
  @extend .zen-alert--secondary;
}

%zen-alert--success {
  @extend .zen-alert--success;
}

%zen-alert--danger {
  @extend .zen-alert--danger;
}

%zen-alert--warning {
  @extend .zen-alert--warning;
}

%zen-alert--info {
  @extend .zen-alert--info;
}

%zen-alert--light {
  @extend .zen-alert--light;
}

%zen-alert--dark {
  @extend .zen-alert--dark;
}
