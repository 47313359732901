/*==============================================================================
// File:        _horizontal-slider.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared horizontal slider component - SASS
//============================================================================*/

.horizontal-slider {
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("row") {
    display: flex;
  }

  @include element("col") {
    margin-right: var(--rk-m-md);
    min-width: 320px;
    max-width: 75vw;

    @media (max-width: 400px) {
      min-width: 320px;
      max-width: 65vw;
    }
  }
}
