/*==============================================================================
// File:        _blog.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main blog styles for the website - SASS
//============================================================================*/

.zen-blog {
  overflow: hidden;
  padding: 0;

  @include element("items-featured") {
    background: var(--rk-base-white);
    padding: 70px 0 110px;

    @media (max-width: 991px) {
      padding: 50px 0 80px;
    }

    @media (max-width: 767px) {
      padding: 20px 0;
    }
  }

  @include element("items-more") {
    background: var(--rk-base-white);
  }

  @include element("hero-title") {
    padding-left: 50px;
    margin: 80px 0;
    border-left: 4px solid var(--rk-primary);

    h1 {
      font-size: 60px;
    }

    @media (max-width: 991px) {
      h1 {
        font-size: 36px;
      }
    }

    @media (max-width: 1440px) {
      border-left: none;
    }
  }

  @include element("hero-subtitle") {
    font-size: 21px;
  }

  // ------------------------------------------------//
  // Blog Pagination
  // ------------------------------------------------ //

  $pagination-icon-size: 16px;

  .pagination {
    .page-item {
      .icon-angle-right:before {
        @extend %fontello;

        content: "\e806";
        font-size: $pagination-icon-size;
        color: var(--rk-base-black);
      }

      .icon-angle-left:before {
        @extend %fontello;

        content: "\e805";
        font-size: $pagination-icon-size;
        color: var(--rk-base-black);
      }

      .icon-angle-double-right:before {
        @extend %fontello;

        content: "\e806\e806";
        font-size: $pagination-icon-size;
        letter-spacing: -10px;
        color: var(--rk-base-black);
      }

      .icon-angle-double-right:after,
      .icon-angle-double-left:after {
        content: "" !important;
      }

      .icon-angle-double-left:before {
        @extend %fontello;

        content: "\e805\e805";
        font-size: $pagination-icon-size;
        letter-spacing: -10px;
        color: var(--rk-base-black);
      }
    }
  }

  @include element("article-pagination") {
    .pagenav {
      @extend .zen-list;
      @extend .zen-list--inline;

      justify-content: center;
      align-items: center;
      padding: var(--rk-p-lg) 0;
      display: flex;

      .next,
      .previous {
        a {
          @extend .zen-btn;
        }
      }

      li {
        a span {
          background-color: transparent;
          display: inline-block;
        }

        .icon-chevron-left,
        .icon-chevron-right {
          @extend %fontello;

          vertical-align: middle;
          padding: 0;
        }
      }
    }
  }
}

// ------------------------------------------------//
// Blog Index - Specific Styling
// ------------------------------------------------ //

.zen-blog-category {
  .pagination__wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
