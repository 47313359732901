/*==============================================================================
// File:        _body.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main body styles for the website - SASS
//============================================================================*/

.zen-body {
  z-index: var(--rk-z-index-bottom);
  position: relative;

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("main") {
    padding: 0;
  }

  @include element("breadcrumbs") {
    padding: 0;

    @include media-breakpoint-down(md) {
      border-top: 1px solid var(--rk-base-grey-lighter);
    }
  }

  @include element("search-links") {
    .inner-links {
      justify-content: space-between;
      align-items: center;
      max-width: 700px;
      margin: 0 auto;
      display: flex;
    }
  }
}
