/*==============================================================================
// File:        _people.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main people styles for the website - SASS
//============================================================================*/

.zen-people {
  .offcanvas-backdrop {
    margin-top: 0;
  }
}

.person-column {
  transition: all .4s ease-in-out;
}

.zen-cta--person {
  margin: 0;
  padding: 0;

  hr {
    border: 4px solid var(--rk-base-grey-light);
  }

  h6 {
    font-weight: 600;
  }

  img {
    transition: all .2s ease-in-out;
    filter: grayscale(100%);
  }

  &:hover {
    img {
      filter: grayscale(0%);
    }
  }
}
