/*==============================================================================
// File:        _map.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared map component - SASS
//============================================================================*/

.zen-map {
  @include fluid-prop(height, $viewport-full-min, $viewport-xl, 350px, 850px);

  overflow: hidden;
  margin: var(--rk-m-xl) 0;
  width: 100%;

  .leaflet-popup-content {
    width: 310px !important;
    padding: 0;
  }

  .mapboxgl-marker-anchor-center {
    background-size: cover;
  }

  @include adjoin("leaflet-container") {
    h4 {
      font-size: var(--rk-font-size-xl);
      color: var(--rk-secondary);
      text-align: left;
      padding: 0;
    }

    p {
      padding: 0 0 var(--rk-m-df);
      text-align: left;
    }

    a {
      text-align: center;
    }
  }

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("label") {
    color: var(--rk-base-black);
    text-shadow:
      2px 2px 0 var(--rk-base-white),
      -2px 2px 0 var(--rk-base-white),
      -2px -2px 0 var(--rk-base-white),
      2px -2px 0 var(--rk-base-white);
    font-weight: 400;
    font-size: 13px;
  }

  @include element("info") {
    color: var(--rk-secondary);
    padding: var(--rk-p-df);
  }

  @include element("sub-title") {
    text-transform: uppercase;
  }
}
