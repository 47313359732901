/*==============================================================================
// File:        _accordion.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared accordion component - SASS
//============================================================================*/

.zen-accordion {
  padding: 0;
  border-radius: 0;

  .accordion-item:first-child * {
    border-top: none;
  }

  .accordion-button:not(.collapsed) {
    background: var(--rk-base-grey-lightest);
    border-left: 4px solid var(--rk-primary);
  }

  .accordion-collapse {
    border-left: 4px solid var(--rk-primary);
  }

  .accordion-item {
    border-radius: 0;
  }

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("btn-main") {
    @extend %zen-btn--accordion-main;
  }

  @include element("btn-sub") {
    @extend %zen-btn--accordion-sub;

    background: var(--rk-secondary);
  }

  @include element("toggles") {
    display: flex;
    justify-content: end;
  }

  @include element("toggle") {
    border: none;
    font-family: var(--rk-secondary-font);
    background: none;

    &.toggle-open {
      border-right: 1px solid var(--rk-base-grey-light);
    }
  }

  @include element("content") {
    padding: 0 var(--rk-p-md);
    margin: 0;

    .zen-text,
    p,
    ul li,
    ol li {
      font-size: var(--rk-font-size-md);
      color: var(--rk-default);
    }
  }
}
