/*==============================================================================
// File:        _type.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main type styling - SASS
//============================================================================*/

h1 {
  @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 36px, 48px);

  font-family: var(--rk-primary-font);
  color: var(--rk-default);

  strong,
  b {
    font-weight: 700;
    color: var(--rk-default);
  }
}

h2 {
  @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 24px, 40px);

  font-family: var(--rk-primary-font);
  color: var(--rk-default);

  strong,
  b {
    font-weight: 700;
    color: var(--rk-default);
  }
}

h3 {
  @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 23px, 36px);

  font-family: var(--rk-primary-font);
  color: var(--rk-default);

  strong,
  b {
    font-weight: 700;
    color: var(--rk-default);
  }
}

h4 {
  @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 22px, 32px);

  font-family: var(--rk-primary-font);
  color: var(--rk-default);

  strong,
  b {
    font-weight: var(--rk-font-bold);
    color: var(--rk-default);
  }
}

h5 {
  @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 20px, 26px);

  font-family: var(--rk-secondary-font);
  color: var(--rk-default);

  strong,
  b {
    font-weight: var(--rk-font-bold);
    color: var(--rk-default);
  }
}

h6 {
  @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 18px, 22px);

  font-family: var(--rk-secondary-font);
  color: var(--rk-default);

  strong,
  b {
    font-weight: var(--rk-font-bold);
    color: var(--rk-default);
  }
}

p {
  @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 14px, 16px);

  margin-bottom: var(--rk-m-df);
  white-space: normal;
  font-family: var(--rk-secondary-font);
  line-height: 1.8;
}

a {
  color: var(--rk-default);

  &:hover,
  &:focus {
    color: var(--rk-primary);
  }
}

/*------------------------------------------------------------------------------
// Extend heading tags
//----------------------------------------------------------------------------*/

%h1 {
  @extend h1;
}

%h2 {
  @extend h2;
}

%h3 {
  @extend h3;
}

%h4 {
  @extend h4;
}

%h5 {
  @extend h5;
}

%h6 {
  @extend h6;
}

%p {
  @extend p;
}
