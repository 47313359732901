/*==============================================================================
// File:        _badge.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared badge component - SASS
//============================================================================*/

.zen-badge {
  font-size: var(--rk-font-size-xxs);
  font-weight: var(--rk-font-bold);
  background: var(--rk-primary);
  color: var(--rk-base-white);
  padding: .5em .75em .25em;
  vertical-align: baseline;
  align-items: center;
  white-space: nowrap;
  position: relative;
  text-align: center;
  border-radius: 0;
  display: flex;

  &:hover,
  &:focus {
    color: var(--rk-base-white);
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("count") {
    background: none;
    color: var(--rk-primary);
    justify-content: center;
    align-items: center;
    position: absolute;
    min-height: 20px;
    min-width: 21px;
    display: none;
    bottom: -2px;
    font-size: 14px;
    right: 59px;
    height: 20px;
    width: 20px;
    border-radius: 50vw;

    @media (max-width: 1500px) {
      right: 55px;
      bottom: -5px;
    }

    &:hover {
      color: var(--rk-primary);
    }

    @include adjoin("show") {
      display: flex;
    }
  }
}
