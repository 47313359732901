/*==============================================================================
// File:        _menu.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared menu component - SASS
//============================================================================*/

/*------------------------------------------------------------------------------
// Zen Menu Styling
//----------------------------------------------------------------------------*/

.zen-menu {
  /*------------------------------------------------------------------------------
  // Elements
  //----------------------------------------------------------------------------*/

  @include element("top") {
    border-bottom: 1px solid var(--rk-base-grey-lighter);
    justify-content: end;
    align-items: center;
    margin: 0 -30px;
    display: flex;
    height: 40px;

    .nav-header,
    .nav-link {
      @extend %zen-link--default;

      font-size: var(--rk-font-size-xs);
      padding-left: var(--rk-p-df);
    }
  }

  @include element("main") {
    font-family: var(--rk-secondary-font-semibold);

    .nav-header,
    .nav-link {
      @extend %zen-link--menu-main;
    }

    .sub-item {
      display: inline-block;
      padding: var(--rk-p-sm) var(--rk-p-sm);
      width: 100%;
    }

    @include media-breakpoint-down(lg) {
      @include insert-transition(all, .3s, ease-in-out, 0s);

      background: var(--rk-base-white);
      z-index: var(--rk-z-index-top);
      font-weight: normal;
      min-width: 300px;
      position: fixed;
      overflow: auto;
      max-width: 65%;
      right: -480px;
      height: 100%;
      opacity: 0;
      margin: 0;
      top: 0;
    }
  }

  @include element("footer") {
    .nav-header,
    .nav-link {
      background: inherit;
    }
  }

  .dropdown-toggle {
    &:after {
      top: unset;
      right: 0;
      content: "\e804";
    }
  }
}

.zen-menu__main .dropdown-toggle {
  position: relative;
}

.zen-menu__collapse-panel {
  position: absolute;
  top: 130px;
  left: 0;
  right: 0;
  z-index: 1000;
  background: white;
  border-top: 1px solid var(--rk-base-grey-light);
  border-bottom: 3px solid var(--rk-primary);

  .zen-list {
    column-count: 3;

    li {
      justify-content: start;
      position: relative;
      transition: all .2s ease-in-out;

      .nav-link {
        font-family: var(--rk-secondary-font);
        text-transform: unset;
        font-weight: 300;
        font-size: 16px;
        color: var(--rk-primary);
      }

      &:hover {
        background: var(--rk-base-grey-lighter);

        &:after {
          right: 5px;
        }
      }

      &:after {
        transition: all .2s ease-in-out;
        font-weight: var(--rk-font-bold);
        font-family: fontello, serif !important;
        content: "";
        position: absolute;
        right: 10px;
        top: 5px;
        bottom: 0;
        font-size: 12px;
        color: var(--rk-base-grey-dark);
        transform: rotate(-90deg);
      }
    }
  }

  .nav-link {
    font-size: 14px;
    font-weight: 400;
    padding: 10px 20px;
    border-top: 1px solid var(--rk-base-grey-light);
    width: 100%;
  }

  .nav-item .nav-link.dropdown-toggle {
    &:after {
      right: 3px;
    }
  }
}

/*------------------------------------------------------------------------------
// Mega Menu Styling (needs refactoring)
//----------------------------------------------------------------------------*/

.zen-mega-menu {
  .dropdown-toggle {
    font-weight: var(--rk-font-bold);
    position: relative;
    font-size: 16px;
    padding: 0 100px;

    &:after {
      font-size: var(--rk-font-size-md);
      font-weight: var(--rk-font-bold);
      content: "\e804";
      top: unset;
      right: 5px;
    }
  }

  .note {
    font-size: 16px;
  }

  .dropdown-menu {
    transition: all .3s ease;
    opacity: 0;
    visibility: hidden;
    box-shadow: 0 5px 15px rgba(var(--rk-base-black), .1);
    border-right: 1px solid var(--rk-border-light);
    border-left: 1px solid var(--rk-border-light);
    border-bottom: 3px solid var(--rk-primary);
    position: absolute;
    border-radius: 0;
    height: 620px;
    top: 115px;
    min-width: 100vw;
    right: 0;
    left: 0;
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    margin: 0 0 0 -30px;
    border-top: 1px solid var(--rk-base-grey-lighter);

    &::-webkit-scrollbar {
      display: none;
    }

    .subitems {
      font-family: var(--rk-secondary-font);
      display: grid;
      grid-template-columns: 2fr 2fr 2fr 2fr;
      padding: 0 10px;
    }
  }

  .dropdown-menu.show {
    opacity: 1;
    visibility: visible;
  }

  .nav-tabs {
    padding: 20px 100px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: var(--rk-primary);
    font-weight: var(--rk-font-semi-bold);
    font-size: var(--rk-font-size-xs);
    border-bottom: none;

    @include media-breakpoint-down(lg) {
      padding: var(--rk-p-xl) 0;
    }

    .zen-link {
      border-bottom: 3px solid var(--rk-secondary);
      border-left: none;
      border-right: none;
      color: var(--rk-primary);
      padding: 10px 30px 10px 0;
      font-weight: var(--rk-font-semi-bold);
      font-size: var(--rk-font-size-xs);
      text-transform: uppercase;
      transition: all .2s ease-in-out;

      &:hover {
        background: var(--rk-base-grey-lighter);
      }
    }

    li.deeper {
      margin: 0;
    }

    .sub-item-deeper {
      position: relative;
      font-size: 14px;
    }

    .sub-item-deeper:hover {
      background: none;
    }

    .sub-item-deeper.active {
      position: relative;
      color: var(--rk-primary-dark);
      border-color: var(--rk-primary);

      &:hover {
        background: none;
      }
    }
  }

  .tab-content {
    position: absolute;
    left: 105px;
    right: 30px;
    top: 80px;
    display: none;

    &.active {
      display: block !important;
    }

    .sub-item {
      color: var(--rk-base-black);
      border: none;
      background: transparent;
      font-size: 16px;
      font-weight: 400;
      text-transform: none;
      padding: var(--rk-p-xs);
      transition: all .2s ease-in-out;

      &:hover {
        background: var(--rk-base-grey-lighter);
      }

      &.active {
        text-decoration: underline;
        text-decoration-color: var(--rk-primary);
        text-decoration-thickness: 2px;
        text-underline-offset: 5px;
      }
    }
  }
}

.europe-menu .zen-list--menu {
  column-count: 4;

  @media (max-width: 991px) {
    column-count: 3;
  }

  @media (max-width: 767px) {
    column-count: 2;
  }
}

/*------------------------------------------------------------------------------
// Mobile menu
//----------------------------------------------------------------------------*/

.zen-menu-open {
  @include media-breakpoint-down(lg) {
    max-height: 100vh;
    overflow: hidden;

    .zen-overlay {
      display: block;
      opacity: 1;
    }

    .sppb-section,
    .zen-header,
    .zen-body {
      z-index: auto !important;
    }

    .sp-page-builder {
      z-index: -1;
    }

    .zen-menu__main {
      transform: translateX(-480px);
      opacity: 1;
    }
  }
}

.mobile-menu {
  @include media-breakpoint-down(lg) {
    box-shadow: none;

    .zen-link {
      text-transform: capitalize;
      font-size: var(--rk-font-size-lead);
      line-height: var(--rk-line-height-xs);
    }

    .top-item {
      .zen-link {
        padding: var(--rk-p-md) var(--rk-p-df) !important;
      }
    }

    .zen-flyout-back {
      padding-bottom: var(--rk-p-sm);
    }

    .zen-menu-flyout {
      .offcanvas-body {
        // Accounts for top, offcanvas-header height and padding for the overflow-scroll to not cut elements off
        height: calc(100vh - 92px - 49.5px - 100px);
      }

      .zen-btn-third {
        padding: var(--rk-p-md) var(--rk-p-df) !important;
        line-height: var(--rk-line-height-xs);
      }

      .zen-link {
        &.child-sub-item {
          padding: var(--rk-p-md) var(--rk-p-df) !important;
        }
      }
    }
  }

  @media (max-width: 991px) {
    box-shadow: none;
    border-top: 1px solid var(--rk-base-grey-light);

    .flyout-submenu-offcanvas {
      box-shadow: none;
      border-top: 1px solid var(--rk-base-grey-light);
      padding-top: var(--rk-p-md);

      .offcanvas-body {
        li .zen-link {
          font-weight: 400 !important;
        }
      }
    }
  }
}

.flyout-menu-offcanvas,
.flyout-submenu-offcanvas {
  position: fixed;
  top: 0;
  background: var(--rk-base-white);
  z-index: $z-index-upper;
  width: 100%;
  max-width: 400px;
  bottom: 0;
  right: 0;
  transition: all .2s ease-in-out;
  box-shadow: 0 0 20px rgba(0, 0, 0, 50%);
  border-left: 3px solid var(--rk-primary);

  .deeper {
    position: relative;
  }
}

/*------------------------------------------------------------------------------
// Extend menu element & modifiers
//----------------------------------------------------------------------------*/

%zen-menu {
  @extend .zen-menu;
}

%zen-menu__footer {
  @extend .zen-menu__footer;
}
