/*==============================================================================
// File:        _reviews.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main review styles for the website - SASS
//============================================================================*/

.zen-reviews {
  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("bar-container") {
    width: 100%;
    background-color: var(--rk-base-grey-lightest);
    border-radius: 5px;
    margin-bottom: 10px;
    display: inline-block;
  }

  @include element("bar") {
    height: 20px;
    width: 100px;
    background-color: var(--rk-primary);
    border-radius: 5px;
  }

  @include element("bar-score") {
    width: 50px;
  }

  @include element("bar-text") {
    width: 100px;
  }

  @include element("bar-section") {
    display: flex;
    gap: 10px;
  }

  @include element("scores-top") {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
  }
}
