/*==============================================================================
// File:        _holiday.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main holiday styles for the website - SASS
//============================================================================*/

.zen-holiday {
  @include element("favourite") {
    .zen-btn {
      .add,
      .remove {
        display: none;
      }
    }

    &:not(.fav-active) {
      .zen-btn .add {
        display: inline;

        @media (max-width: 768px) {
          display: none;
        }
      }
    }

    &.fav-active {
      .zen-btn .remove {
        display: inline;

        @media (max-width: 768px) {
          display: none;
        }
      }

      .zen-icon:before {
        content: "\e80c" !important;
      }
    }
  }

  @include element("videos") {
    iframe {
      height: auto;
      width: 100%;
      aspect-ratio: 16 / 9;
    }
  }

  @include element("section") {
    scroll-margin-top: 100px;
  }

  @include element("hero") {
    &-left {
      min-height: 860px;

      @media only screen and (max-width: 991px) {
        min-height: 360px;
      }
    }

    &-right {
      .right-panel-wrapper {
        user-select: none;
        padding: 60px;
        margin-right: 100px;
        margin-left: -100px;
        background: var(--rk-base-white);
        margin-top: 80px;
        transition: all .15s cubic-bezier(.8, 0, .2, 1);
        position: relative;

        @media only screen and (max-width: 1200px) {
          margin-right: -30px;
          margin-left: -160px;
        }

        @media only screen and (max-width: 991px) {
          margin: 0;
          padding: 0;
        }
      }

      .price-panel {
        background: var(--rk-base-grey-lighter);
      }

      .days,
      .price {
        display: inline-block;
      }

      .days-number,
      .days-text,
      .days-from {
        font-size: 30px;
        color: var(--rk-primary);
        font-weight: 500;

        @media only screen and (max-width: 1200px) {
          font-size: 26px;
        }

        @media only screen and (max-width: 991px) {
          font-size: 22px;
        }
      }

      .price-number {
        font-size: 30px;
        color: var(--rk-primary);
        font-weight: 700;

        @media only screen and (max-width: 1200px) {
          font-size: 26px;
        }

        @media only screen and (max-width: 991px) {
          font-size: 22px;
        }
      }

      .price-text {
        font-family: var(--rk-secondary-font);
        font-size: 16px;
        color: var(--rk-primary);
      }

      .dates-text {
        font-family: var(--rk-secondary-font);
        font-size: 15px;
      }
    }

    .zen-holiday__hero-title {
      transition:
        max-height .3s ease-in-out,
        visibility .6s ease-in-out,
        opacity .3s ease-in-out;
      max-height: 600px;

      @media only screen and (max-width: 1200px) {
        padding: 30px 0 !important;
        font-size: 28px;
      }
    }

    .scrolled .zen-holiday__hero-title {
      visibility: hidden;
      max-height: 0;
      opacity: 0;
      padding: 0 !important;
      margin: 0 !important;
    }

    .right-panel-wrapper.scrolled {
      padding: 20px 40px 40px;
      margin-top: 20px;
      background: none;
      margin-right: -30px;
      margin-left: -40px;
      top: 65px !important;
      z-index: 120;

      @media only screen and (max-width: 991px) {
        margin: 0;
        padding: 0;
        position: fixed;
        left: 0;
        right: 0;
        bottom: 0;
        top: unset !important;
        z-index: 100;
      }
    }
  }

  @include element("expert-speaker") {
    .zen-holiday__speakers-slider {
      position: relative;
    }

    .card-title {
      font-family: var(--rk-primary-font);
    }

    .slick-prev,
    .slick-next {
      position: absolute;
      top: -50px;
      transform: translateY(-50%);
      z-index: 1;
      width: 40px;
      height: 40px;
      border: 1px solid var(--rk-base-grey);
      color: var(--rk-primary);
      font-size: 30px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .slick-list {
      margin: 0 !important;
    }

    .slick-prev {
      right: 50px;
      left: unset !important;
    }

    .slick-next {
      right: 0;
    }
  }

  @include element("musicians") {
    .zen-holiday__musicians-slider {
      position: relative;

      .slick-list {
        .slick-track {
          display: flex;
        }

        .slick-slide {
          height: inherit !important;
        }

        .card {
          height: 100%;
        }
      }
    }

    .zen-holiday__musician-item {
      padding: 0 10px;
    }

    .slick-prev,
    .slick-next {
      position: absolute;
      top: -50px;
      transform: translateY(-50%);
      z-index: 1;
      width: 40px;
      height: 40px;
      border: 1px solid var(--rk-base-grey);
      color: var(--rk-primary);
      font-size: 30px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .slick-list {
      margin: 0 !important;
    }

    .slick-prev {
      right: 50px;
      left: unset !important;
    }

    .slick-next {
      right: 0;
    }
  }

  @include element("quick-links") {
    user-select: none;
    left: 0;
    right: 0;
    z-index: 110;
    background: var(--rk-base-white);
    border-bottom: 1px solid var(--rk-base-grey-light);
  }

  @include element("main") {
    background: var(--rk-base-white);
  }

  @include element("overview") {
    font-family: var(--rk-secondary-font);
    background: var(--rk-light-purple);
    border-left: 4px solid var(--rk-primary);
    padding: 50px;
    column-count: 2;
    column-gap: 50px;

    @media only screen and (max-width: 991px) {
      column-count: 1;
    }

    ul {
      padding: 0;
      margin: 0;
    }

    li {
      padding: 8px;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 10px;

      &::marker {
        content: "■";
        font-size: 12px;
        color: var(--rk-primary);
      }
    }
  }

  @include element("description") {
    p:first-of-type {
      font-size: 1.1rem;
    }
  }

  .related-articles {
    background: var(--rk-base-white);
  }

  @include element("section-fade") {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0%) 0%, var(--rk-base-white) 100%);
  }

  @include element("review-item") {
    .card {
      border: none;
      border-top: 5px solid var(--rk-base-grey-lightest);
      background: none;
      margin-top: 30px;

      .card-body {
        padding: 1.5rem;
      }
    }
  }

  @include element("dates") {
    &-year-title {
      font-size: 20px;
      font-weight: 600;
      font-family: var(--rk-primary-font);
    }

    &-table-head {
      border-top: 2px solid var(--rk-base-grey-light);

      p {
        font-weight: 700;
        font-size: 14px;
        margin-bottom: 0;
      }
    }

    p.table-cell-label {
      font-weight: bold;
    }

    .departure-list {
      border-bottom: 1px solid var(--rk-base-grey-light);
    }

    &-table-row {
      border-top: 1px solid var(--rk-base-grey-light);
      padding: 10px 0;

      p {
        margin-bottom: 0;
        font-size: 14px;
      }

      .table-cell-price {
        font-weight: 700;
        color: var(--rk-primary);
      }
    }

    &:last-child {
      border-bottom: 1px solid var(--rk-base-grey-light);
    }
  }

  @include element("related") {
    padding: 60px 0;
    background: url("/images/inscriptions/9.jpg?1725983319000");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  @include element("related-articles") {
    .horizontal-slider__col {
      padding-bottom: 50px !important;
    }
  }

  @include element("bottom") {
    z-index: 120;
    position: relative;
  }
}
