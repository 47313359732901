/*==============================================================================
// File:        _link.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared link component - SASS
//============================================================================*/

.zen-link {
  text-decoration: none;
  color: var(--rk-default);

  &:hover,
  &:focus {
    text-decoration: none;
    color: var(--rk-primary);
    cursor: pointer;
  }

  /*==============================================================================
	// Elements
	//============================================================================*/

  @include element("icon") {
    font-size: var(--rk-icon-size-df);
  }

  /*==============================================================================
	// Modifiers
	//============================================================================*/

  @include modifier("in-content") {
    text-decoration: none;
    padding: 0 0 8px;
    transition: all .2s ease-in-out;
    font-size: 17px;

    &:hover,
    &:focus {
      color: var(--rk-primary);
    }
  }

  @include modifier("spotify") {
    padding: 5px 15px;
    background: var(--rk-base-grey-lighter);
    border-radius: 50vw;
    transition: all .2s ease-in-out;

    i {
      transition: all .2s ease-in-out;
    }

    &:hover,
    &:focus {
      background: var(--rk-primary);
      color: var(--rk-base-white);

      i {
        color: var(--rk-base-white);
      }
    }
  }

  @include modifier("muted") {
    color: var(--rk-base-black-light);

    &:hover,
    &:focus {
      color: var(--rk-base-black);
    }
  }

  @include modifier("telephone") {
    color: var(--rk-primary);
    font-family: var(--rk-secondary-font-semibold);
    font-size: 18px;
  }

  @include modifier("invert") {
    color: var(--rk-secondary);

    &:hover,
    &:focus {
      color: var(--rk-primary);
    }
  }

  @include modifier("no-underline") {
    text-decoration: none;
  }

  @include modifier("default") {
    color: var(--rk-default);

    &:hover,
    &:focus {
      color: var(--rk-default);
    }
  }

  @include modifier("default-underline") {
    color: var(--rk-default);
    border-bottom: 1px solid var(--rk-base-grey);

    &:hover,
    &:focus {
      text-decoration: underline;
      color: var(--rk-default);
    }
  }

  @include modifier("default-full-underline") {
    text-decoration: underline;
    color: var(--rk-default);

    &:hover,
    &:focus {
      text-decoration: underline;
      color: var(--rk-default);
    }
  }

  @include modifier("light") {
    color: var(--rk-base-white);

    &:hover,
    &:focus {
      color: var(--rk-base-white);
    }
  }

  @include modifier("primary") {
    color: var(--rk-primary);

    &:hover,
    &:focus {
      color: var(--rk-primary-light);
    }
  }

  @include modifier("primary-underline") {
    color: var(--rk-primary);

    &:hover,
    &:focus {
      text-decoration: underline;
      color: var(--rk-secondary);
    }
  }

  @include modifier("primary-full-underline") {
    text-decoration: underline;
    color: var(--rk-primary);

    &:hover,
    &:focus {
      text-decoration: underline;
      color: var(--rk-secondary);
    }
  }

  @include modifier("secondary") {
    color: var(--rk-secondary);

    &:hover,
    &:focus {
      color: var(--rk-secondary-light);
    }
  }

  @include modifier("image-zoom") {
    overflow: hidden;

    img {
      @include insert-transition(transform, 2s, ease, .1s);

      z-index: -1;
    }

    &:hover {
      img {
        transform: scale(1.15);
      }

      div > :not(img) {
        @include insert-transition(border-color, 2s, ease, .1s);

        border-color: $primary-border-hover;
      }
    }
  }

  @include modifier("image-grey-to-colour") {
    .grayscale {
      @include insert-transition(filter, .4s, ease, 0s);

      filter: grayscale(1);
    }

    &:hover {
      .grayscale {
        filter: grayscale(0);
      }
    }
  }

  @include modifier("search-filter") {
    border: 1px solid var(--rk-base-white);
    font-weight: var(--rk-font-semi-bolder);
    padding: var(--rk-p-sm) var(--rk-p-df);
    background: $search-link-bg;
    text-transform: capitalize;
    color: var(--rk-base-white);
    margin: 0 0 var(--rk-m-df);
    text-align: center;
    display: block;

    &:hover,
    &:focus {
      background: var(--rk-base-black);
      color: var(--rk-base-white);
    }
  }

  @include modifier("pagination-active") {
    border-color: var(--rk-primary);
    background: var(--rk-primary);
    color: var(--rk-base-white);
  }

  /*------------------------------------------------------------------------------
  // Animated Items
  //----------------------------------------------------------------------------*/

  @include modifier("bounce") {
    animation-timing-function: cubic-bezier(.28, .84, .42, 1);
    animation-iteration-count: infinite;
    animation-name: scroll-bounce;
    transform-origin: center;
    animation-duration: 4s;
  }

  @keyframes scroll-bounce {
    0% { transform: translateY(0); }
    30% { transform: translateY(-10px); }
    50% { transform: translateY(0); }
    80% { transform: translateY(-7px); }
    100% { transform: translateY(0); }
  }

  /*------------------------------------------------------------------------------
  // Menu Link Items
  //----------------------------------------------------------------------------*/

  @include modifier("menu") {
    position: relative;
  }

  @include modifier("menu-main") {
    line-height: var(--rk-line-height-md);
    font-size: 14px;
    font-weight: var(--rk-font-bold);
    color: var(--rk-base-black);
    text-decoration: none;
    align-items: center;
    padding: var(--rk-p-sm);
    display: flex;

    &:hover,
    &:focus {
      color: var(--rk-primary);
    }

    @include adjoin("dropdown-toggle") {
      padding: var(--rk-p-sm) var(--rk-p-md) var(--rk-p-sm) var(--rk-p-sm);
    }
  }

  @include modifier("menu-sub") {
    font-family: var(--rk-secondary-font);
    padding: var(--rk-link-p-md) var(--rk-link-p-md);
    text-transform: capitalize;
    font-size: var(--rk-font-size-df);
    color: var(--rk-text);
    white-space: normal;

    &:hover,
    &:focus {
      background: var(--rk-base-grey-lighter);
      color: var(--rk-text);
    }
  }

  @include modifier("menu-mobile") {
    display: inline-block;
    padding: var(--rk-link-p-sm);
    color: var(--rk-primary);
    width: 100%;

    &:hover,
    &:focus {
      color: var(--rk-primary);
    }
  }

  @include modifier("number") {
    letter-spacing: var(--rk-letter-space-xl);
    font-size: 16px;
  }

  @include modifier("shadow") {
    text-shadow: 1px 1px 5px var(--rk-text-shadow);
  }

  /*------------------------------------------------------------------------------
  // Letter Space Changes
  //----------------------------------------------------------------------------*/

  @include modifier("letter-space-df") {
    letter-spacing: var(--rk-letter-space-df);
  }

  @include modifier("letter-space-md") {
    letter-spacing: var(--rk-letter-space-md);
  }

  @include modifier("letter-space-lg") {
    letter-spacing: var(--rk-letter-space-lg);
  }

  @include modifier("letter-space-xl") {
    letter-spacing: var(--rk-letter-space-xl);
  }

  /*------------------------------------------------------------------------------
	// Font Size Changes
	//----------------------------------------------------------------------------*/

  @include modifier("text-xl") {
    font-size: var(--rk-font-size-xl);
  }

  @include modifier("text-lg") {
    font-size: var(--rk-font-size-lg);
  }

  @include modifier("text-md") {
    font-size: var(--rk-font-size-md);
  }

  @include modifier("text-sm") {
    font-size: var(--rk-font-size-sm);
  }

  @include modifier("text-xs") {
    font-size: var(--rk-font-size-xs);
  }
}

/*------------------------------------------------------------------------------
// Extend link element & modifiers
//----------------------------------------------------------------------------*/

%zen-link {
  @extend .zen-link;
}

%zen-link--in-content {
  @extend .zen-link--in-content;
}

%zen-link--default {
  @extend .zen-link--default;
}

%zen-link--default-underline {
  @extend .zen-link--default-underline;
}

%zen-link--default-full-underline {
  @extend .zen-link--default-full-underline;
}

%zen-link--primary {
  @extend .zen-link--primary;
}

%zen-link--secondary {
  @extend .zen-link--secondary;
}

%zen-link--light {
  @extend .zen-link--light;
}

%zen-link--pagination-active {
  @extend .zen-link--pagination-active;
}

%zen-link--menu-main {
  @extend .zen-link--menu-main;
}

%zen-link--menu-sub {
  @extend .zen-link--menu-sub;
}

%zen-link--menu-mobile {
  @extend .zen-link--menu-mobile;
}
