/*==============================================================================
// File:        _card.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared card component - SASS
//============================================================================*/

.zen-card {
  background: var(--rk-base-grey-lightest);
  border: 1px solid var(--rk-base-grey-lightest);
  border-radius: 0;
  margin: 0;

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("image-container") {
    padding: var(--rk-p-df);
  }

  @include element("image") {
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
    display: flex;
    padding: 0;
    margin: 0;

    @include modifier("max-height") {
      max-height: 285px;
      overflow: hidden;
    }
  }

  @include element("title") {
    margin: var(--rk-m-md) 0;
  }

  @include element("heading") {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 18px, 22px);

    font-family: var(--rk-primary-font);
    color: var(--rk-primary);
    font-weight: bold;
  }

  @include element("body") {
    padding: var(--rk-p-df);
  }

  @include element("info") {
    padding: var(--rk-p-df);
  }

  @include element("content") {
    padding: var(--rk-p-sm) 0;

    ul {
      @extend %zen-list;
      @extend %zen-list--default;
    }
  }

  @include element("interactive") {
    position: absolute;
    bottom: -1px;
    right: 0;
    background: var(--rk-base-grey-lightest);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
  }

  @include element("favourite") {
    color: var(--rk-default-lighter);
    user-select: none;
    padding: 15px;

    &:hover,
    &:focus {
      color: var(--rk-default);
    }

    @include adjoin("fav-active") {
      .icon-heart,
      .zen-icon {
        color: var(--rk-primary);

        &:before {
          content: "\e80c" !important;
          position: relative;
        }

        &:hover,
        &:focus {
          color: var(--rk-default);
        }
      }

      .icon-heart-fav {
        &:before {
          content: "\e81c" !important;
          position: relative;
        }
      }
    }
  }

  @include element("image-wrapper") {
    position: relative;
  }

  @include element("dates") {
    span {
      font-family: var(--rk-secondary-font);
      font-weight: bold;
    }
  }

  @include element("speaker") {
    span {
      font-family: var(--rk-secondary-font);
      font-weight: bold;
    }
  }

  @include element("description") {
    padding: var(--rk-p-df);
  }

  @include element("info") {
    border-top: 1px solid var(--rk-border-colour);
    flex-direction: column;
    text-transform: capitalize;
    justify-content: center;
    padding: var(--rk-p-df);
    display: flex;
  }

  @include element("footer") {
    padding: var(--rk-p-df);
    border: none;
    margin: 10px;
    border-top: 1px solid var(--rk-base-grey);
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("person") {
    .card-main-body p {
      font-size: 13px !important;
    }
  }

  @include modifier("tall") {
    .zen-card--featured-card__inner {
      height: 500px;
    }
  }

  @include modifier("featured-card") {
    @include element("inner") {
      position: relative;
      height: 400px;
    }

    @include element("image") {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;

      .card-image {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        background-size: cover;
      }
    }

    @include element("title") {
      font-family: var(--rk-primary-font);
      margin-bottom: 10px;
    }

    @include element("content") {
      position: absolute;
      left: 10px;
      right: 10px;
      bottom: -30px;
      display: flex;
      align-items: start;
      justify-content: center;
      padding: 20px;
      flex-direction: column;
      background: var(--rk-base-white);
    }

    @include element("date") {
      font-family: var(--rk-secondary-font);
    }
  }

  @include modifier("white") {
    .zen-card,
    .zen-card__interactive,
    .zen-card__body,
    .zen-card__footer {
      background: var(--rk-base-white);
    }

    .zen-card__interactive {
      background: var(--rk-base-white);
    }
  }

  @include modifier("blog-sidebar") {
    background: var(--rk-base-grey-lightest);
    border: none;
    box-shadow: none;
  }

  @include modifier("blog-card") {
    overflow: hidden;
    border: none;
    box-shadow: none;
    padding: 10px;

    @include element("image") {
      position: relative;

      .card-image {
        height: 140px;
        background-size: cover;
      }
    }

    @include element("date") {
      font-family: var(--rk-secondary-font);
    }
  }

  @include modifier("related") {
    @include insert-transition(all, .15s, ease-in-out, 0s);

    margin: 20px 0;
    max-width: 510px;

    .zen-card__body {
      min-height: 240px;
    }

    .zen-card__footer {
      min-height: 91px;
    }

    &:focus,
    &:hover {
      border: 1px solid var(--rk-base-grey);
      cursor: pointer;

      .zen-btn {
        background: var(--rk-primary);
        color: var(--rk-base-white);
        border: 1px solid var(--rk-primary);
      }
    }
  }

  @include modifier("search") {
    @include insert-transition(all, .15s, ease-in-out, 0s);

    &:focus,
    &:hover {
      @include insert-shadow(0, 0, 15px, -2px, var(--rk-shadow));

      cursor: pointer;
    }
  }

  @include modifier("exclusive") {
    border: 1px solid var(--rk-primary);
  }

  @include modifier("note") {
    padding: var(--rk-p-lg) var(--rk-p-xl);
  }

  @include modifier("full-height") {
    /*Rollback Display*/
    display: block;
    display: grid;
    height: 100%;
  }
}

/*------------------------------------------------------------------------------
// Extend menu element & modifiers
//----------------------------------------------------------------------------*/

%zen-card {
  @extend .zen-card;
}

%zen-card__image {
  @extend .zen-card__image;
}

%zen-card__title {
  @extend .zen-card__title;
}

%zen-card__heading {
  @extend .zen-card__heading;
}

%zen-card__body {
  @extend .zen-card__body;
}

%zen-card__info {
  @extend .zen-card__info;
}

%zen-card__footer {
  @extend .zen-card__footer;
}
