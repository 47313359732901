/*==============================================================================
// File:        _blog-article.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main blog article styles for the website - SASS
//============================================================================*/

.zen-blog-article {
  padding: 0;

  h1 {
    font-family: var(--rk-primary-font) !important;
  }

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("info-bar") {
    border-top: 1px solid var(--rk-base-grey-lighter);
  }

  @include element("heading") {
    font-size: 26px;
    margin-left: -200px;
    margin-top: 5vw;
    width: 100%;
    background: var(--rk-base-white);
    padding: 30px !important;

    @media (max-width: 991px) {
      margin-left: 0;
      margin-top: 0;
      border-bottom: 1px solid var(--rk-base-grey-lighter);
    }
  }

  @include element("social-bar") {
    justify-content: space-between;
    margin: 0 0 var(--rk-p-df);
    align-items: center;
    display: flex;
  }

  @include element("image") {
    position: relative;
    overflow: hidden;

    @media (min-width: 768px) {
      max-height: 600px;
    }

    &:after {
      border: 2px solid var(--rk-border-light);
      content: "";
      position: absolute;
      left: 20px;
      right: 20px;
      top: 20px;
      bottom: 20px;
    }
  }

  @include element("body") {
    margin: 0 auto var(--rk-m-xxl);
    padding: var(--rk-p-md) 0;
    max-width: 1100px;
    font-family: var(--rk-secondary-font);

    a:not(.sppb-btn, .zen-link, .zen-btn) {
      @extend %zen-link;
      @extend %zen-link--in-content;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: var(--rk-secondary-font);
      margin-bottom: 30px;
      text-decoration: none;
      border-bottom: none !important;

      a {
        border-bottom: none;
        color: var(--rk-primary);
      }
    }

    h1 {
      @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 26px, 30px);
    }

    h2 {
      @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 24px, 28px);
    }

    h3 {
      @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 22px, 26px);
    }

    h4 {
      @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 20px, 24px);
    }

    h5 {
      @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 18px, 22px);
    }

    h6 {
      @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 16px, 20px);
    }
  }

  .pagenavigation {
    .icon-chevron-left {
      @extend %fontello;

      &:before {
        content: "\e805";
      }
    }

    .icon-chevron-right {
      @extend %fontello;

      &:before {
        content: "\e806";
      }
    }
  }
}

// tour sliders shouldn't go on article pages,
// but this will make sure they look a bit better if they do
.zen-blog-article .sppb-addon-holidays .js-holiday-slider .slick-slide {
  height: fit-content !important;

  .zen-card__heading {
    font-family: var(--rk-primary-font);
  }

  @media (max-width: 968px) {
    min-width: 310px !important;
  }

  @media (min-width: 968px) {
    min-width: 450px !important;
  }
}
