/*==============================================================================
// File:        _cta.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared cta component - SASS
//============================================================================*/

.zen-cta {
  position: relative;
  padding: var(--rk-p-df);
  color: var(--rk-text);

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("text") {
    color: var(--rk-text);
  }

  /*------------------------------------------------------------------------------
  // Mr Zen SPPB Feature Box
  //----------------------------------------------------------------------------*/

  @include element("featurebox-image") {
    @include flex--center;

    margin: 0;

    &:before {
      @include pseudo;

      background: var(--rk-image-overlay-bg);
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;
    }

    &:hover {
      &:before {
        background: var(--rk-image-overlay-bg-hover);
      }
    }
  }

  @include element("date") {
    background: var(--rk-primary);
    color: var(--rk-base-white);
    font-size: var(--rk-font-size-xs);
    padding: var(--rk-p-xs);
    align-self: flex-start;
    font-style: italic;
    font-weight: var(--rk-font-bold);
  }

  @include element("featurebox-icon") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 100px, 140px);

    color: var(--rk-primary);
  }

  @include element("featurebox-content") {
    border: 2px solid var(--rk-border-light);
    font-weight: var(--rk-font-bold);
    position: relative;
    color: var(--rk-base-white);
    align-items: end;
    padding: var(--rk-p-df);
    display: flex;
    height: 100%;

    &:after {
      @include pseudo;

      @extend %fontello;

      content: "\e823";
      font-size: 48px;
      padding: var(--rk-p-xs);
      bottom: 0;
      right: 0;
    }

    p {
      @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 20px, 32px);

      text-shadow: 1px 1px 10px var(--rk-text-shadow);
      border-left: 4px solid var(--rk-primary);
      padding: var(--rk-p-xs) 60px 0 var(--rk-p-df);
      margin: 0 !important;
      line-height: normal;
      position: absolute;
      width: 100%;
    }
  }

  @include element("featurebox-caption") {
    font-weight: var(--rk-font-semi-bolder);
    font-size: var(--rk-font-size-xs);
    background: var(--rk-primary);
    padding: .25em .55em;
    position: absolute;
    text-align: center;
    font-style: italic;
    left: 15px;
  }

  /*------------------------------------------------------------------------------
  // Mr Zen SPPB Feature Button
  //----------------------------------------------------------------------------*/

  @include element("featurebutton-image") {
    margin: 0 var(--rk-m-df) 0 0;
  }

  @include element("featurebutton-icon") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 30px, 70px);

    margin: 0 var(--rk-m-df) 0 0;
    line-height: 1em;
    color: var(--rk-primary);
  }

  @include element("featurebutton-title") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 14px, 24px);

    font-family: var(--rk-primary-font);
    text-transform: uppercase;
    color: var(--rk-text);
  }

  @include element("person-collapse") {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 100;
    background: var(--rk-base-grey-lightest);
    border-top: 1px solid var(--rk-base-grey-dark);
    border-bottom: 1px solid var(--rk-base-grey-dark);
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/
  // ------------------------------------------------//
  // CTA 'Regular'
  // ------------------------------------------------//

  @include modifier("regular") {
    padding: 0;
    border-top: 8px solid var(--rk-base-grey-lightest);
    padding-top: 10px;
    margin-top: 30px;

    h3 {
      font-size: 24px;
      margin-top: 15px;
    }

    p {
      font-size: 16px;
    }

    a {
      text-decoration: none;
    }
  }

  // ------------------------------------------------//
  // CTA 'Micro'
  // ------------------------------------------------//

  @include modifier("micro") {
    text-decoration: none;
    padding: 0;

    .zen-cta__title {
      font-size: 22px;
      margin-top: 10px;
      color: var(--rk-primary);
    }

    .zen-cta__link {
      text-decoration: none;
    }

    .zen-cta__content {
      display: none;
    }

    .zen-btn {
      display: none;
    }

    .zen-cta__image {
      position: relative;

      img {
        width: 100%;
      }

      &:after {
        content: ">";
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        background: var(--rk-base-white);
        color: var(--rk-base-black);
        width: 30px;
        height: 30px;
        position: absolute;
        right: 0;
        bottom: 0;
      }
    }
  }
  // ------------------------------------------------//
  // CTA Blog Card Featured
  // ------------------------------------------------//

  @include modifier("featured-blog") {
    text-decoration: none;
    padding: 0;

    @include element("inner") {
      position: relative;
      height: 400px;
    }

    @include element("image") {
      position: absolute;
      background-size: cover;
      background-position: center;
      inset: 0;
    }

    @include element("content") {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px;
    }

    @include element("title") {
      font-size: 22px;
      color: var(--rk-base-white);
      text-shadow: 0 0 10px var(--rk-text-shadow);
    }

    @include element("caption") {
      color: var(--rk-base-white);
      text-shadow: 0 0 10px var(--rk-text-shadow);
    }
  }

  // ------------------------------------------------//
  // Blog Card Standard
  // ------------------------------------------------//

  @include modifier("blog-card") {
    border-top: 1px solid var(--rk-base-grey-light);
    padding: var(--rk-p-df) 0;
    text-decoration: none;

    @include element("inner") {
      height: 250px;
    }

    @include element("image") {
      background-color: (--rk-base-grey-light);
      padding: 0;

      .card-image {
        background-size: cover;
        background-color: var(--rk-base-grey-lighter);
        height: 100%;
      }
    }

    @include element("title") {
      font-size: 22px;
    }

    @include element("date") {
      background: var(--rk-primary);
      color: var(--rk-base-white);
      font-size: var(--rk-font-size-xs);
      display: inline-block;
      margin-top: var(--rk-m-sm);
      padding: var(--rk-p-xs);
      font-style: italic;
      font-weight: var(--rk-font-bold);
    }
  }

  @include modifier("no-pad") {
    padding: 0;
  }

  @include modifier("btn") {
    display: inline-block;
    text-align: center;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  @include modifier("featurebox") {
    margin: 0 auto var(--rk-m-xl);
    overflow: hidden;
    padding: 0;

    .sppb-addon-text-block {
      position: absolute;
      padding: var(--rk-p-df);
      bottom: 0;
      right: 0;
      left: 0;
      top: 0;
    }
  }

  @include modifier("featurebutton") {
    @include flex--center;
    @include insert-shadow(0, 0, 10px, -2px, var(--rk-shadow));

    border-top-right-radius: var(--rk-border-radius-df);
    border-top-left-radius: var(--rk-border-radius-df);
    border: 1px solid var(--rk-base-grey-light);
    border-bottom: 6px solid var(--rk-primary);
    text-align: center;
    margin: 0 0 var(--rk-m-xl);
    padding: var(--rk-p-sm);
  }

  /*------------------------------------------------------------------------------
  // Box Type
  //----------------------------------------------------------------------------*/

  @include modifier("info") {
    .zen-cta__featurebox-content p {
      padding: 0 60px var(--rk-p-xxl) 0;
      border: none;
    }
  }

  /*------------------------------------------------------------------------------
  // Box Size
  //----------------------------------------------------------------------------*/

  @include modifier("small") {
    container-type: inline-size;
    container-name: small-cta;
    max-width: 300px;
  }

  @include modifier("medium") {
    container-type: inline-size;
    container-name: medium-cta;
    max-width: 500px;
  }

  @include modifier("large") {
    container-type: inline-size;
    container-name: large-cta;
    max-width: none;
  }

  /*------------------------------------------------------------------------------
  // Box Shape
  //----------------------------------------------------------------------------*/

  @include modifier("square") {
    aspect-ratio: 1/1;

    .zen-cta__featurebox-image {
      height: 100%;
    }

    .sppb-img-responsive,
    .img-fluid {
      max-width: fit-content;
      height: 100%;
    }
  }

  @include modifier("rectangle") {
    aspect-ratio: 16/7.7;

    .zen-cta__featurebox-image {
      height: 100%;
    }

    .sppb-img-responsive,
    .img-fluid {
      aspect-ratio: 16/9;
    }
  }

  /*------------------------------------------------------------------------------
  // Font Size Changes
  //----------------------------------------------------------------------------*/

  @include modifier("text-xl") {
    font-size: var(--rk-font-size-xl);
  }

  @include modifier("text-lg") {
    font-size: var(--rk-font-size-lg);
  }

  @include modifier("text-md") {
    font-size: var(--rk-font-size-md);
  }

  @include modifier("text-sm") {
    font-size: var(--rk-font-size-sm);
  }

  @include modifier("text-xs") {
    font-size: var(--rk-font-size-xs);
  }
}

/*------------------------------------------------------------------------------
// Extend cta element & modifiers
//----------------------------------------------------------------------------*/

%zen-cta {
  @extend .zen-cta;
}

/*------------------------------------------------------------------------------
// Container queries
//----------------------------------------------------------------------------*/

// Small CTA.
//
@container small-cta (max-width: 300px) {
  .zen-cta__featurebox-content p,
  .sppb-addon-text-block .zen-cta__featurebox-content p {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 20px, 24px);
  }
}

@container small-cta (max-width: 200px) {
  .zen-cta__featurebox-content p,
  .sppb-addon-text-block .zen-cta__featurebox-content p {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 18px, 20px);

    padding: 0 0 0 var(--rk-p-df);

    &:after {
      display: none;
    }
  }
}

@container small-cta (max-width: 100px) {
  .zen-cta__featurebox-content p,
  .sppb-addon-text-block .zen-cta__featurebox-content p {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 16px, 18px);
  }
}

// Medium CTA.
//
@container medium-cta (max-width: 500px) {
  .zen-cta__featurebox-content p,
  .sppb-addon-text-block .zen-cta__featurebox-content p {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 34px, 38px);
    @include fluid-prop(line-height, $viewport-xs, $viewport-xl, 1.2, 1.4);
  }
}

@container medium-cta (max-width: 400px) {
  .zen-cta__featurebox-content p,
  .sppb-addon-text-block .zen-cta__featurebox-content p {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 24px, 34px);
  }
}

@container medium-cta (max-width: 300px) {
  .zen-cta__featurebox-content p,
  .sppb-addon-text-block .zen-cta__featurebox-content p {
    @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 18px, 24px);

    padding: 0 0 0 var(--rk-p-df);

    &:after {
      display: none;
    }
  }
}

// Large CTA.
//
@container large-cta (max-width: 800px) {
  .sppb-addon-text-block:not(.rectangle) {
    .zen-cta__featurebox-content p {
      @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 54px, 64px);
    }

    @include adjoin("rectangle") {
      @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 30px, 40px);
    }
  }
}

@container large-cta (max-width: 600px) {
  .sppb-addon-text-block:not(.rectangle) {
    .zen-cta__featurebox-content p {
      @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 44px, 54px);
    }

    @include adjoin("rectangle") {
      @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 26px, 30px);
    }
  }
}

@container large-cta (max-width: 400px) {
  .sppb-addon-text-block:not(.rectangle) {
    .zen-cta__featurebox-content p {
      @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 34px, 44px);

      padding: 0 0 0 var(--rk-p-df);

      &:after {
        display: none;
      }
    }

    @include adjoin("rectangle") {
      @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 22px, 26px);

      padding: 0 0 0 var(--rk-p-df);

      &:after {
        display: none;
      }
    }
  }
}

@container large-cta (max-width: 300px) {
  .sppb-addon-text-block:not(.rectangle) {
    .zen-cta__featurebox-content p {
      @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 24px, 34px);
    }

    @include adjoin("rectangle") {
      @include fluid-prop(font-size, $viewport-xs, $viewport-xl, 20px, 24px);
    }
  }
}
