blockquote {
  position: relative;

  .quote {
    opacity: .4;
    font-size: 5rem;
    color: var(--rk-primary);
    line-height: 1;
  }

  .quote-open {
    position: absolute;
    top: -5px;
    left: -10px;
  }

  .quote-close {
    display: inline;
    position: relative;
    position: absolute;
    bottom: 0;
    line-height: 0;
    transform: translateX(-20px);
  }

  p {
    display: inline;
    font-size: 20px;
    font-style: italic;
    color: var(--rk-default);
    margin: 0 2rem;
    font-family: var(--rk-primary-font);
  }
}
