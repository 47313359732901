.zen-flyout {
  display: flex;
  flex-direction: column;

  @include element("close-btn") {
    border: none;
    background: none;
  }

  @include element("back-btn") {
    border: none;
    background: none;
  }

  @include element("panel") {
    overflow-y: scroll;
    scrollbar-width: none;
    transition: all .2s ease-in-out;
    position: fixed;
    height: 100%;
    right: 0;
    top: 0;
    bottom: 0;
    width: 400px;
    background: white;
    z-index: 100;
    transform: translateX(0);
    box-shadow: 0 0 20px rgba(0, 0, 0, 20%);
    display: block;
    padding-bottom: 50px;

    &.collapse {
      transform: translateX(0);
      display: block;
    }

    &.collapse:not(.show) {
      height: 100%;
      display: block !important;
      right: -450px;
    }
  }

  @include element("link") {
    padding: 8px;
    width: 100%;
    font-size: 17px;
    text-decoration: none;
    font-weight: 700;
    font-family: var(--rk-secondary-font);
    color: var(--rk-primary);
    display: block;
    position: relative;

    &:hover {
      background: var(--rk-base-grey-lighter);
    }

    &.dropdown:after {
      content: "\e806";
      font-family: fontello;
      position: absolute;
      right: 10px;
      top: 5px;
    }
  }

  @include element("panel-content") {
    padding: 1rem;

    .zen-list {
      display: flex;
      flex-direction: column;
    }
  }
}

.offcanvas-header {
  border-bottom: 1px solid var(--rk-base-grey-lighter);
}
