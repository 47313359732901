/*==============================================================================
// File:        _fonts.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main web font references - SASS
//============================================================================*/

/*------------------------------------------------------------------------------
// ZEN Icons - Source: "http://fontello.com/"
//----------------------------------------------------------------------------*/

@font-face {
  font-family: fontello;
  src: url("../fonts/fontello.ttf");
  src:
    url("../fonts/fontello.eot#iefix") format("embedded-opentype"),
    url("../fonts/fontello.woff") format("woff"),
    url("../fonts/fontello.eot") format("embedded-opentype"),
    url("../fonts/fontello.svg#fontello") format("svg");
  font-weight: normal;
  font-display: swap;
  font-style: normal;
}

.fontello {
  /*Use important to prevent issues*/
  font-family: fontello, serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-variant: normal;
  text-transform: none;
  font-weight: normal;
  font-style: normal;
  line-height: 1;
  speak: none;

  &:before {
    margin: 0 !important;
  }
}

.icon-calendar:before { content: "\e802"; }
.icon-camera:before { content: "\e803"; }
.icon-caret-down:before { content: "\e804"; }
.icon-caret-left:before { content: "\e805"; }
.icon-caret-right:before { content: "\e806"; }
.icon-caret-up:before { content: "\e807"; }
.icon-caret-up-down:before { content: "\e808"; }
.icon-chat:before { content: "\e809"; }
.icon-chat-circle:before { content: "\e80a"; }
.icon-heart:before { content: "\e80b"; }
.icon-heart-solid:before { content: "\e80c"; }
.icon-check:before { content: "\e80d"; }
.icon-facebook:before { content: "\e80e"; }
.icon-instagram-logo:before { content: "\e80f"; }
.icon-hamburger:before { content: "\e810"; }
.icon-search:before { content: "\e811"; }
.icon-map-pin:before { content: "\e812"; }
.icon-person:before { content: "\e813"; }
.icon-phone:before { content: "\e814"; }
.icon-play:before { content: "\e815"; }
.icon-filters:before { content: "\e816"; }
.icon-spotify:before { content: "\e817"; }
.icon-user:before { content: "\e818"; }
.icon-user-square:before { content: "\e819"; }
.icon-vimeo:before { content: "\e81a"; }
.icon-youtube:before { content: "\e81b"; }
.icon-close:before { content: "\e81c"; }
.icon-x-logo:before { content: "\e81d"; }
.icon-arrow-left:before { content: "\e81f"; }
.icon-arrow-right:before { content: "\e820"; }
.icon-arrow-square-left:before { content: "\e821"; }
.icon-arrow-square-right:before { content: "\e822"; }
.icon-sys-close { content: "\e81c"; }

/*------------------------------------------------------------------------------
// Font Awesome Overrides
//----------------------------------------------------------------------------*/
.fa {
  &-long-arrow-left {
    &:before {
      content: "\e801";
    }
  }

  &-long-arrow-right {
    &:before {
      content: "\e802";
    }
  }
}

/*------------------------------------------------------------------------------
// Extend Icon Class
//----------------------------------------------------------------------------*/

%fontello {
  @extend .fontello;
}
