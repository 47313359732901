.zen-recent-reviews {
  .slider-for {
    .slick-slide {
      margin-top: 0;
      height: unset;
    }
  }

  .slick-track,
  .slick-list {
    padding: 0;
    margin: 0;
  }

  .slick-arrow {
    position: relative;
  }

  @include element("card") {
    display: flex;
    flex-direction: column;
    align-items: start !important;

    @media only screen and (max-width: 991px) {
      padding: 50px 30px;
    }
  }

  @include element("caption") {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 20px;
    bottom: 20px;
    background: rgba(0, 0, 0, 80%);
    padding: 10px;
    color: white;
  }

  @include element("image") {
    position: relative;
  }

  @include element("text") {
    position: relative;
    font-size: 22px;
    padding: 20px;
    font-style: italic;
    display: inline;
    font-family: var(--rk-primary-font);
  }

  @include element("quote-open") {
    position: absolute;
    font-size: 50px;
    color: var(--rk-primary);
    opacity: .5;
    left: 0;
    top: -10px;
  }

  @include element("quote-closed") {
    position: absolute;
    font-size: 50px;
    color: var(--rk-primary);
    opacity: .5;
    bottom: -5px;
  }

  @include element("controls") {
    display: flex;
    gap: 10px;

    .slick-arrow {
      height: 50px;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      border: 1px solid var(--rk-base-grey);

      i {
        line-height: 0;

        &:before {
          font-size: 20px;
        }
      }
    }
  }
}
