/*==============================================================================
// File:        _header.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main header styles for the website - SASS
//============================================================================*/

.zen-header {
  z-index: var(--rk-z-index-top);
  width: 100%;
  padding: 0;
  top: -1px;
  user-select: none;

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("main") {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  @include element("logo") {
    margin-bottom: 30px;
    margin-top: 30px;

    img {
      height: 75px;

      @include insert-transition(height, .3s, ease, 0s);

      @include media-breakpoint-down(lg) {
        height: 50px;
      }
    }
  }

  @include element("main-menu") {
    align-self: center;
    justify-self: center;

    .nav-item {
      border-right: 1px solid var(--rk-base-grey-light);
      padding: 0 12px;
      list-style: none;
    }

    .item-206 {
      border-right: none;
    }

    .zen-link--menu.nav-link.dropdown-toggle {
      margin-right: 20px;
    }

    .dropdown .nav-item {
      padding: 0;
    }

    .dropdown-item.active {
      color: var(--rk-default);
    }

    .nav-link {
      text-transform: uppercase;
    }

    .zen-mega-menu {
      border-right: 1px solid var(--rk-base-grey-light);

      @include element("view-all") {
        padding: 0;
        margin: 10px 30px;
        border-top: 1px solid var(--rk-base-grey-light);
      }

      .zen-list--menu {
        li {
          border-right: 1px solid var(--rk-base-grey);
        }

        li:first-child {
          border-left: 1px solid var(--rk-base-grey);
        }
      }

      .dropdown-toggle.nav-link {
        padding: 25px 20px;
      }
    }
  }

  .nav-wrapper {
    display: flex;
  }

  @include element("mobile-items") {
    span {
      font-size: 12px;
      font-family: var(--rk-secondary-font-semibold);
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  @include element("right-bar") {
    display: none;
    background: var(--rk-grey-lightest);
    align-self: flex-start;
    justify-self: flex-start;
    padding: 45px 0 40px 50px;

    @media (min-width: 1600px) {
      display: flex;
    }

    a {
      border-right: 1px 0 80px solid var(--rk-base-grey);
      border-left: 1px solid var(--rk-base-grey);
    }

    a:first-child,
    a:last-child {
      border-left: none;
      border-right: none;
    }

    .zen-menu-open {
      .zen-header {
        z-index: var(--rk-z-index-top);
      }
    }
  }

  .js-phone-icon {
    margin-bottom: 2px;

    @include media-breakpoint-down(sm) {
      margin-bottom: 3px;
    }
  }
}
