/*==============================================================================
// File:        _footer.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main footer styles for the website - SASS
//============================================================================*/

.zen-footer {
  z-index: 300 !important;
  background: var(--rk-base-white);
  color: var(--rk-secondary);
  position: relative;

  .sppb-addon-content a {
    text-decoration: none !important;
  }

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("main") {
    @include media-breakpoint-down(lg) {
      text-align: center;

      .sppb-text-left {
        text-align: center;
      }

      .zen-footer-menu .zen-list {
        flex-direction: column;
        justify-content: center;
        text-align: center;
        column-gap: normal;
        column-rule: none;
        column-count: 1;
        max-width: 100%;
        margin: 0 auto;
        display: flex;

        li {
          justify-content: center;
          display: flex;
        }
      }
    }
  }

  @include element("center") {
    ul li {
      font-size: 17px;
      font-family: var(--rk-secondary-font);
    }
  }

  @include element("signup") {
    background: var(--rk-primary);
    color: var(--rk-base-white);

    .btn {
      @extend %zen-btn;

      width: auto;
    }
  }

  @include element("signup-container") {
    padding: var(--rk-p-df) var(--rk-p-md) var(--rk-p-xxl);
    max-width: 600px;
    margin: 0 auto;
  }

  @include element("copyright") {
    position: relative;
    z-index: 300 !important;
    font-size: var(--rk-font-size-xs);
    border-top: 1px solid var(--rk-base-grey-light);
    padding: var(--rk-p-lg) var(--rk-p-df);
    background: var(--rk-base-white);
    color: var(--rk-secondary);
    margin: 0;
    text-align: center;

    @include media-breakpoint-down(md) {
      margin: 0 0 var(--rk-m-df);
    }

    a {
      color: var(--rk-secondary);
    }
  }

  @include element("copyright-text") {
    color: var(--rk-default);
    font-family: var(--rk-secondary-font);
  }
}

.zen-page-bottom {
  z-index: 300 !important;
}
