/*==============================================================================
// File:        _search.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main search styles for the website - SASS
//============================================================================*/

.zen-search {
  padding: 0 0 var(--rk-p-xxl);

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("top-bar") {
    padding: var(--rk-p-df) var(--rk-p-lg);
    border-bottom: 3px solid var(--rk-primary);
    justify-content: space-between;
    margin: 0 -30px var(--rk-m-df);
    align-items: center;
    min-height: 75px;
    max-height: 90px;
    display: flex;
  }

  @include element("tags") {
    margin: 0 0 var(--rk-m-df);
  }

  @include element("results") {
    padding: 0;
  }
}
