.zen-custom-carousel {
  @include element("right") {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include element("left") {
    overflow: hidden;

    video {
      width: 100%;
    }
  }

  .slick-dots {
    display: none;
  }

  @include element("right-inner") {
    background: var(--rk-base-white);
    z-index: 1000;
    padding: 30px;

    .main-body {
      font-weight: 400;
    }

    @include modifier("bordered") {
      border-left: 10px solid var(--rk-base-grey-lightest);
    }

    @media (min-width: 1200px) {
      padding: 60px;
    }
  }
}

.margin-right-offset {
  width: 100%;

  @media (min-width: 991px) {
    margin-right: -100px;
    margin-left: 100px;
  }

  @media (min-width: 1200px) {
    margin-right: -200px;
    margin-left: 200px;
  }
}

.margin-left-offset {
  width: 100%;

  @media (min-width: 991px) {
    margin-left: -100px;
    margin-right: 100px;
  }

  @media (min-width: 1200px) {
    margin-left: -200px;
    margin-right: 200px;
  }
}

.zen-slider {
  @include element("caption-wrapper") {
    position: absolute;
    bottom: 30px;
    left: 30px;

    @include modifier("right") {
      left: unset;
      right: 30px;
    }

    @media (max-width: 768px) {
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  @include element("caption-text") {
    padding-left: 10px;
    color: var(--rk-base-white);
    font-family: var(--rk-secondary-font);
    text-decoration: none;

    &:hover,
    &:focus,
    &:active {
      color: var(--rk-base-white);
      text-decoration: underline;
    }
  }

  @include element("caption-link") {
    display: flex;
    color: var(--rk-base-white);
    text-decoration: none;
  }

  @include element("caption-container") {
    cursor: pointer;
    background: rgba(0, 0, 0, 70%);
    transition: background .3s ease-in-out;
    backdrop-filter: blur(5px);
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--rk-base-white);

    &:hover {
      background: rgba(0, 0, 0, 90%);
    }
  }

  .slick-list {
    margin: 0;
  }

  .slick-slide {
    margin: 0;

    @media (max-width: 991px) {
      min-height: 400px !important;
    }
  }

  .slick-arrow {
    background: rgba(0, 0, 0, 70%);
    backdrop-filter: blur(5px);
    color: var(--rk-base-white);
    opacity: 1;
    font-size: 16px;
  }

  .slick-prev {
    right: 80px;
    top: 40px;
    left: unset !important;
  }

  .slick-next {
    right: 30px !important;
    top: 40px;
  }
}

.zen-horizontal-slider {
  @media (max-width: 768px) {
    padding: 0 30px !important;
  }

  .slick-list {
    margin: 0;
  }

  .slick-arrow {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--rk-primary-clear);

    i {
      margin: 0;
    }
  }

  .slick-prev {
    top: -10px;
    right: 65px;
    left: unset !important;
  }

  .slick-next {
    right: 15px !important;
    top: -10px;
  }
}
