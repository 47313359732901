/*==============================================================================
// File:        _button.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared button component - SASS
//============================================================================*/

.zen-btn {
  @include insert-transition(all, .5s, ease, .1s);

  letter-spacing: var(--rk-letter-space-md);
  text-transform: uppercase;
  z-index: var(--rk-z-index-bottom);
  font-size: var(--rk-font-size-xs);
  font-family: var(--rk-secondary-font-semibold);
  justify-content: center;
  font-weight: 700;
  text-decoration: none;
  display: inline-flex;
  align-items: end;
  padding: var(--rk-p-md) var(--rk-p-df);
  position: relative;
  line-height: 1em;
  border-radius: 0;
  border: 1px solid var(--rk-primary);
  background: var(--rk-primary);
  color: var(--rk-base-white);

  i {
    margin-right: var(--rk-p-sm);
  }

  &:active,
  &:focus {
    background: var(--rk-secondary);
    color: var(--rk-primary);
    border: 1px solid var(--rk-secondary-dark);
    text-decoration: none;
    box-shadow: none;
    cursor: pointer;
    outline: none;
  }

  &:hover {
    background: var(--rk-secondary);
    color: var(--rk-primary);
    border: 1px solid var(--rk-secondary-dark);
    text-decoration: none;
    box-shadow: none;
    cursor: pointer;
    outline: none;

    i {
      color: var(--rk-primary);
    }
  }

  &:disabled {
    border-color: var(--rk-base-grey);
    background: var(--rk-base-grey);
    cursor: not-allowed;
    color: var(--rk-text);
    opacity: .6;
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/
  // ---------------------------------------------
  // Primary Button Styling
  // ---------------------------------------------
  @include modifier("primary") {
    border: 1px solid var(--rk-primary);
    background: var(--rk-primary);
    color: var(--rk-base-white);

    &:after {
      background: var(--rk-base-white);
    }

    &:hover,
    &:focus {
      border: 1px solid var(--rk-primary-light);
      background: var(--rk-primary-light);
      color: var(--rk-base-white);

      &:after {
        background: var(--rk-base-white);
      }
    }
  }

  @include modifier("mega") {
    background: none;
    color: var(--rk-primary);
    border: none;
    border-bottom: 4px solid var(--rk-base-grey-light);

    &:hover {
      border: none;
      background: none;
      border-bottom: 4px solid var(--rk-base-grey-dark);
    }

    &.active {
      background: none;
      border: none;
      border-bottom: 4px solid var(--rk-primary);
    }
  }

  // ---------------------------------------------
  // Secondary Button Styling
  // ---------------------------------------------
  @include modifier("secondary") {
    border: 1px solid var(--rk-secondary);
    background: var(--rk-secondary);
    color: var(--rk-primary);

    &:hover,
    &:focus {
      border: 1px solid var(--rk-primary);
      background: var(--rk-primary);
      border-color: var(--rk-primary);
      color: var(--rk-base-white);
    }
  }

  // ---------------------------------------------
  // Alternate Button Styling
  // ---------------------------------------------
  @include modifier("alternate") {
    border: 1px solid var(--rk-primary);
    background: var(--rk-primary);
    color: var(--rk-secondary);

    &:hover,
    &:focus {
      border: 1px solid var(--rk-primary);
      background: var(--rk-primary);
      color: var(--rk-secondary);
    }
  }

  // ---------------------------------------------
  // Disabled Button Styling
  // ---------------------------------------------
  @include modifier("disabled") {
    border: 1px solid var(--rk-base-grey);
    background: var(--rk-base-grey);
    color: var(--rk-text);
    opacity: .8;

    &:hover,
    &:focus {
      border: 1px solid var(--rk-base-grey);
      background: var(--rk-base-grey);
      color: var(--rk-text);
      opacity: .8;
    }
  }

  // ---------------------------------------------
  // Danger Button Styling
  // ---------------------------------------------
  @include modifier("danger") {
    border: 1px solid var(--rk-danger);
    background: var(--rk-danger);
    color: var(--rk-base-white);

    &:hover,
    &:focus {
      border: 1px solid var(--rk-danger-dark);
      background: var(--rk-danger-dark);
    }
  }

  // ---------------------------------------------
  // Warning Button Styling
  // ---------------------------------------------
  @include modifier("warning") {
    border: 1px solid var(--rk-warning);
    background: var(--rk-warning);
    color: var(--rk-base-white);

    &:hover,
    &:focus {
      border: 1px solid var(--rk-warning-dark);
      background: var(--rk-warning-dark);
    }
  }

  // ---------------------------------------------
  // Success Button Styling
  // ---------------------------------------------
  @include modifier("success") {
    border: 1px solid var(--rk-success);
    background: var(--rk-success);
    color: var(--rk-base-white);

    &:hover,
    &:focus {
      border: 1px solid var(--rk-success-dark);
      background: var(--rk-success-dark);
    }
  }

  // ---------------------------------------------
  // Invert Button Styling
  // ---------------------------------------------
  @include modifier("invert") {
    background: var(--rk-primary-light);
    border: 1px solid var(--rk-primary-light);

    &:hover,
    &:focus {
      border: 1px solid var(--rk-primary);
      background: var(--rk-primary);
    }
  }

  @include modifier("link-bar") {
    background: white;
    border: 1px solid white;
    color: var(--rk-primary);
  }

  @include modifier("transparent") {
    padding: var(--rk-p-df) var(--rk-p-md);
    border: 2px solid var(--rk-primary);
    background: transparent;
    color: var(--rk-primary);

    &:hover,
    &:focus {
      border: 2px solid var(--rk-primary);
      background: transparent;
      color: var(--rk-primary);
    }
  }

  @include modifier("link") {
    border: none;
    color: var(--rk-primary) !important;
    font-weight: var(--rk-font-bold);
    background: transparent;
    margin: var(--rk-m-sm) 0 0;
    padding: 15px 50px 15px 0;

    &:hover,
    &:focus {
      border-top: none;
      border-left: none;
      border-right: none;
      color: var(--rk-primary-dark) !important;
      background: transparent;
    }

    &:after {
      @include insert-transition(all, .5s, ease, .1s);

      content: "";
      position: absolute;
      width: 30px;
      height: 2px;
      background: var(--rk-primary);
      right: 10px;
      top: 20px;
    }
  }

  @include modifier("dropdown") {
    padding: var(--rk-p-df) var(--rk-p-md) var(--rk-p-df) 0;
    font-weight: var(--rk-font-bold);
    text-transform: uppercase;
    background: transparent;
    color: var(--rk-text);
    border: none;

    &:after {
      content: "\e807" !important;
      rotate: 180deg;
    }

    &.all-filters {
      &:after {
        content: "\e816" !important;
      }
    }

    &:hover,
    &:focus {
      color: var(--rk-primary-light);
      background: transparent;
      border: none;
    }

    @include adjoin("all-filters") {
      padding: var(--rk-p-df) 25px var(--rk-p-df) 0;

      &:after {
        @include pseudo;

        @extend %fontello;

        font-size: var(--rk-font-size-lg);
        color: var(--rk-primary);
        content: "\e816";
        border: none;
        right: 0;
      }
    }
  }

  @include modifier("itinerary-tab") {
    background: none;
    margin-right: 8px;
    border-bottom: 0;
    border-color: var(--rk-base-grey-lightest);
    font-size: 16px;
    color: #333;
    transition: .2s ease-in-out;
    position: relative;
    padding-bottom: 25px;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      transition: all .2s ease-in-out;
      background: var(--rk-primary);
    }

    &:hover {
      background: var(--rk-base-grey-lightest);
    }

    &.active {
      color: #333;
      background: var(--rk-base-grey-lightest);
      font-weight: 700;

      &:after {
        height: 5px;
      }
    }
  }

  @include modifier("default") {
    border: 1px solid var(--rk-default);
    background: var(--rk-default);

    &:hover,
    &:focus {
      border: 1px solid var(--rk-default-light);
      background: var(--rk-default-light);
    }
  }

  @include modifier("dark") {
    background: var(--rk-dark);
    border: 1px solid var(--rk-dark);
    color: var(--rk-base-white);

    &:hover,
    &:focus {
      border: 1px solid var(--rk-primary-dark);
      background: var(--rk-primary);
      color: var(--rk-base-white);
    }
  }

  @include modifier("full-size") {
    width: 100%;
  }

  @include modifier("placeholder") {
    min-width: 100px;
  }

  @include modifier("extra-pad-lg") {
    padding: var(--rk-p-md) var(--rk-p-lg);
  }

  @include modifier("extra-pad-md") {
    padding: var(--rk-p-sm) var(--rk-p-md);
  }

  @include modifier("extra-pad-sm") {
    padding: var(--rk-p-sm) var(--rk-p-sm);
  }

  @include modifier("extra-y-pad-lg") {
    padding-bottom: var(--rk-p-lg) !important;
    padding-top: var(--rk-p-lg) !important;
  }

  @include modifier("scroll") {
    border: 1px solid var(--rk-primary-mix-dark);
    border-radius: var(--rk-border-radius-df);
    background: var(--rk-primary);
    color: var(--rk-base-white);
    font-size: 30px;
    display: none;
    padding: 8px;

    &:hover,
    &:focus {
      background: var(--rk-primary-light);
      color: var(--rk-base-white);
    }

    @include adjoin("show-btn") {
      display: flex;
    }

    :before {
      vertical-align: top;
    }
  }

  /*------------------------------------------------------------------------------
	// Outlined Buttons
	//----------------------------------------------------------------------------*/

  @include modifier("outlined-primary") {
    border: 2px solid var();
    background: transparent;
    font-weight: var(--rk-font-bold);
    color: var(--rk-primary);

    .zen-icon {
      transition: all .3s ease-in-out;
    }

    &:hover,
    &:focus {
      border-color: var(--rk-primary);
      color: var(--rk-base-white);
      background: var(--rk-primary);

      .zen-icon:before {
        color: var(--rk-base-white);
      }
    }

    .zen-icon:before {
      color: var(--rk-primary);
    }
  }

  @include modifier("outlined-primary-clear") {
    border: 2px solid var(--rk-primary-clear);
    background: transparent;
    font-weight: var(--rk-font-bold);
    color: var(--rk-primary);

    .zen-icon {
      transition: all .3s ease-in-out;
    }

    &:hover,
    &:focus {
      border-color: var(--rk-primary);
      color: var(--rk-base-white);
      background: var(--rk-primary);

      .zen-icon:before {
        color: var(--rk-base-white);
      }
    }

    .zen-icon:before {
      color: var(--rk-primary);
    }
  }

  @include modifier("outlined-white") {
    box-shadow: 0 0 12px -2px var(--rk-box-shadow);
    border: 1px solid var(--rk-base-white);
    text-shadow: 0 1px 5px var(--rk-base-black);
    font-weight: var(--rk-font-bold);
    background: transparent;
    color: var(--rk-base-white);

    &:hover,
    &:focus {
      box-shadow: 0 0 12px -2px var(--rk-box-shadow);
      border-color: var(--rk-base-white);
      background: var(--rk-base-white);
      color: var(--rk-primary);
      text-shadow: none;
    }
  }

  @include modifier("outlined-secondary") {
    border: 1px solid var(--rk-secondary);
    background: var(--rk-base-white);
    font-weight: var(--rk-font-bold);
    color: var(--rk-text);

    &:hover,
    &:focus {
      background: var(--rk-secondary-light);
      border-color: var(--rk-secondary);
      color: var(--rk-base-white);
    }
  }

  @include modifier("outlined-opaque") {
    border: 1px solid var(--rk-primary);
    font-weight: var(--rk-font-bold);
    background: transparent;
    color: var(--rk-primary);

    &:hover,
    &:focus {
      border-color: var(--rk-secondary);
      color: var(--rk-secondary);
      background: transparent;
    }
  }

  @include modifier("quick-link") {
    transition: all .3s ease-in-out;
    background: var(--rk-base-white);
    padding: var(--rk-p-md) var(--rk-p-df);
    font-size: var(--rk-font-size-df);
    margin: 0 0 var(--rk-m-df);
    text-align: left;
    border-radius: 0;
    color: var(--rk-base-black);
    border: 0;
    letter-spacing: 0;
    position: relative;
    text-transform: uppercase;
    font-weight: 400;

    &:hover,
    &:focus {
      background: var(--rk-base-white);
      color: var(--rk-primary);
      border: none;
    }

    &.active:after {
      right: 0;
    }

    &:after {
      content: "";
      background: var(--rk-primary);
      position: absolute;
      left: 0;
      right: 100%;
      transition: all .3s ease-in-out;
      bottom: -16px;
      height: 5px;
    }
  }

  /*------------------------------------------------------------------------------
	// Accordion Buttons
	//----------------------------------------------------------------------------*/

  @include modifier("accordion-main") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 16px, 18px);

    border: none;
    color: var(--rk-primary);
    font-weight: var(--rk-font-bold);
    justify-content: start;
    border-radius: 0;
    padding: 20px 20px 25px;
    margin: 0;
    position: relative;
    text-transform: unset;

    &:hover,
    &:focus {
      cursor: pointer;
      outline: none;
    }

    &:after {
      @extend %fontello;

      transform: rotate(0deg);
      content: "\f105";
      position: absolute;
      right: 20px;
      top: 20px;
      font-size: 30px;
      color: var(--rk-primary);
      transition: all .3s ease-in-out;
      transform-origin: center;
    }

    &:not(.collapsed):after {
      transform: rotate(-180deg);
    }
  }

  @include modifier("accordion-sub") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 14px, 16px);

    transition: all .3s ease-in-out;
    background: var(--rk-base-white);
    padding: var(--rk-p-md) var(--rk-p-df);
    font-weight: var(--rk-font-semi-bolder);
    text-transform: capitalize;
    justify-content: start;
    border-radius: 0;
    margin: 0;
    border-left: 10px solid var(--rk-base-grey-lightest);
    position: relative;
    font-size: 20px;
    color: var(--rk-primary);

    &:not(.collapse),
    &:hover {
      background: var(--rk-base-white) !important;
      color: var(--rk-base-black);
      cursor: pointer;
      outline: none;
      border: none;
      text-transform: math-auto;
    }

    &:focus {
      background: var(--rk-base-grey-light);
      color: var(--rk-text);
      border: none;
    }

    &:after {
      @extend %fontello;

      font-size: var(--rk-icon-size-sm);
      color: var(--rk-primary);
      transition: all .3s ease-in-out;
      transform-origin: center;
      transform: rotate(0deg);
      content: "\e804";
      position: absolute;
      background: none;
      right: 20px;
      top: 20px;

      @include media-breakpoint-down(md) {
        top: 15px;
      }
    }

    &:not(.collapsed):after {
      transform: rotate(180deg);
      background: none;
    }
  }

  @include modifier("accordion-itinerary") {
    @include fluid-prop(font-size, $viewport-full-min, $viewport-xl, 14px, 16px);

    font-weight: var(--rk-font-semi-bolder);
    transition: all .3s ease-in-out;
    cursor: pointer;
    padding: var(--rk-p-md) var(--rk-p-df);
    margin: 0 0 var(--rk-m-df);
    text-align: left;
    border-radius: 0;
    color: var(--rk-base-black);
    border-bottom: 1px solid var(--rk-base-grey-light);

    @include media-breakpoint-down(lg) {
      font-size: var(--rk-font-size-lead);
      border: none;
    }

    &[aria-expanded="true"] {
      border: none;
    }

    &:not(.collapse),
    &:hover {
      color: var(--rk-base-black);
      outline: none;
    }

    &:after {
      transform: rotate(90deg);
    }

    &:not(.collapsed):after {
      transform: rotate(-90deg);
    }
  }

  @include modifier("accordion-mobilemenu") {
    padding: 13.6px var(--rk-p-xl) 13.5px var(--rk-p-sm);
    font-weight: var(--rk-font-bold);
    position: relative;

    &:after {
      @extend %fontello;

      transform: rotate(0deg);
      content: "\f105";
      position: absolute;
      right: 10px;
      height: 20px;
      width: 20px;
      color: var(--rk-grey);
    }
  }

  /*------------------------------------------------------------------------------
	// Button style for flyout Menu
	//----------------------------------------------------------------------------*/

  @include modifier("mobile-nav") {
    border-top: 1px solid var(--rk-base-grey-light);
    font-size: var(--rk-font-size-md);
    text-transform: uppercase;
    font-weight: var(--rk-font-bold);
    padding: 16px;
  }

  @include modifier("flyout-mobilemenu") {
    font-size: var(--rk-font-size-md);
    border: none;
    position: relative;
    padding: var(--rk-p-md);
    color: var(--rk-primary);
    font-weight: var(--rk-font-bold);
    background: var(--rk-base-white);

    &:hover,
    &:focus {
      background: var(--rk-base-white);
      color: var(--rk-primary);
      outline: none;
      border: none;
    }

    &:after {
      @extend %fontello;

      content: "\e807";
      color: var(--rk-secondary);
      position: absolute;
      right: 10px;
      height: 20px;
      width: 20px;
      transform: rotate(0deg);
    }
  }

  /*------------------------------------------------------------------------------
	// Filter Panel
	//----------------------------------------------------------------------------*/

  @include modifier("navigate") {
    border: none;

    /*Reset Border*/
    border-bottom: 1px solid var(--rk-base-grey);
    justify-content: space-between;
    text-transform: uppercase;
    background: var(--rk-base-white);
    align-items: center;
    font-weight: normal;
    margin: 0 0 var(--rk-m-df);
    text-align: unset;
    color: var(--rk-secondary);
    display: flex;

    &:hover,
    &:focus {
      background: var(--rk-base-white);
      color: var(--rk-secondary);
      outline: none;
    }
  }

  /*------------------------------------------------------------------------------
	// Font Size Changes
	//----------------------------------------------------------------------------*/

  @include modifier("text-xl") {
    font-size: var(--rk-font-size-xl);
  }

  @include modifier("text-lg") {
    font-size: var(--rk-font-size-lg);
  }

  /*Default btn text size medium*/
  @include modifier("text-sm") {
    font-size: var(--rk-font-size-sm);
  }

  @include modifier("text-xs") {
    font-size: var(--rk-font-size-xs);
  }
}

/*------------------------------------------------------------------------------
// Extend button element & modifiers
//----------------------------------------------------------------------------*/

%zen-btn {
  @extend .zen-btn;
}

%zen-btn--primary {
  @extend .zen-btn--primary;
}

%zen-btn--alternate {
  @extend .zen-btn--alternate;
}

%zen-btn--secondary {
  @extend .zen-btn--secondary;
}

%zen-btn--accordion-itinerary {
  @extend .zen-btn--accordion-itinerary;
}

%zen-btn--link {
  @extend .zen-btn--link;
}

%zen-btn--default {
  @extend .zen-btn--default;
}

%zen-btn--warning {
  @extend .zen-btn--warning;
}

%zen-btn--danger {
  @extend .zen-btn--danger;
}

%zen-btn--success {
  @extend .zen-btn--success;
}

%zen-btn--dark {
  @extend .zen-btn--dark;
}

%zen-btn--outlined-primary {
  @extend .zen-btn--outlined-primary;
}

%zen-btn--outlined-primary-clear {
  @extend .zen-btn--outlined-primary-clear;
}

%zen-btn--outlined-secondary {
  @extend .zen-btn--outlined-secondary;
}

%zen-btn--outlined-opaque {
  @extend .zen-btn--outlined-opaque;
}

%zen-btn--full-size {
  @extend .zen-btn--full-size;
}

%zen-btn--text-xs {
  @extend .zen-btn--text-xs;
}

%zen-btn--accordion-main {
  @extend .zen-btn--accordion-main;
}

%zen-btn--accordion-sub {
  @extend .zen-btn--accordion-sub;
}
