/*==============================================================================
// File:        _notice.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared notice component - SASS
//============================================================================*/

.zen-offcanvas {
  .offcanvas-header {
    border-bottom: 3px solid var(--rk-primary);
    padding: var(--rk-p-lg) var(--rk-p-lg);
    text-transform: uppercase;

    .zen-title {
      font-size: var(--rk-font-size-lg);
      font-weight: 800;
      letter-spacing: 1.3px;
    }
  }

  .offcanvas-body {
    padding: var(--rk-p-xl) var(--rk-p-md);
  }

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("small") {
    min-width: 40vw;

    @include media-breakpoint-down(lg) {
      min-width: 100vw;
    }
  }

  @include modifier("medium") {
    min-width: 60vw;

    @include media-breakpoint-down(lg) {
      min-width: 100vw;
    }
  }

  @include modifier("large") {
    min-width: 80vw;

    @include media-breakpoint-down(md) {
      min-width: 100vw;
    }
  }

  /*==============================================================================
  // Elements
  //============================================================================*/

  @include element("footer") {
    box-shadow: 0 2px 10px 0 $box-shadow-dark;
    background: var(--rk-base-white);
    z-index: var(--rk-z-index-df);
    padding: var(--rk-p-df);
    justify-content: center;
    align-items: center;
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 0;
  }
}
