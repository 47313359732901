/*==============================================================================
// File:        _variables.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Main variables & reusable items - SASS
//============================================================================*/

/*------------------------------------------------------------------------------
// Global Variables / Bootstrap 5 :root variables
//----------------------------------------------------------------------------*/

$primary: #6D2463;
$primary-light: lighten($primary, 5%);
$primary-lighter: lighten($primary, 10%);
$primary-lightest: lighten($primary, 15%);
$primary-dark: darken($primary, 5%);
$primary-clear: rgba(109, 26, 99, 40%);

$secondary: #EDE4EC;
$secondary-light: lighten($secondary, 5%);
$secondary-lighter: lighten($secondary, 10%);
$secondary-dark: darken($secondary, 5%);

$default-lighter: rgba(#000, .5);
$default-light: #3B3B3B;
$default-dark: #262626;
$default: #262626;

$text: $default;
$light-purple: #EAE3EB;
$grey-lightest: #F2F2F2;
$grey-lighter: #D9D9D9;
$grey-light: #ADADAD;
$grey: #8F8F8F;
$green: #9BD5B8;
$mint: #61BD8F;

$success: #427742;
$success-dark: darken($success, 5%);

$warning: #FFC107;
$warning-dark: darken($warning, 5%);

$danger: #C03442;
$danger-dark: darken($danger, 5%);

$error: var(--bs-red);
$info: var(--bs-info);

$base-orange: var(--bs-orange);
$base-off-white: #F5F3F4;
$base-white: #FFF;
$base-black-light: #191919;
$base-black: #000;
$base-grey-lightest: #F2F2F2;
$base-grey-lighter: #EAEAEA;
$base-grey-light: #DDD;
$base-grey-darker: #979797;
$base-grey-dark: #AAA;
$base-grey: #CCC;

$primary-mix-darkest: mix($primary, $base-black, 30%);
$primary-mix-darker: mix($primary, $base-black, 20%);
$primary-mix-dark: mix($primary, $base-black, 10%);
$primary-mix-lightest: mix($primary, $base-white, 30%);
$primary-mix-lighter: mix($primary, $base-white, 20%);
$primary-mix-light: mix($primary, $base-white, 10%);

$secondary-mix-darkest: mix($secondary, $base-black, 30%);
$secondary-mix-darker: mix($secondary, $base-black, 20%);
$secondary-mix-dark: mix($secondary, $base-black, 10%);
$secondary-mix-lightest: mix($secondary, $base-white, 30%);
$secondary-mix-lighter: mix($secondary, $base-white, 20%);
$secondary-mix-light: mix($secondary, $base-white, 10%);

$classic: #44728E;
$light: var(--bs-light);
$dark: $base-black;

$image-overlay-bg: rgba($base-black, .2);
$image-overlay-bg-hover: rgba($base-black, .1);
$shadow-light: rgba($base-black, .1);
$shadow: rgba($base-black, .2);
$shadow-base: 0 0 20px rgba($base-black, .3);
$border-light: rgba($base-white, .3);
$text-shadow: rgba($base-black, .5);
$search-link-bg: rgba($base-black, .4);
$box-shadow: rgba($base-black, .06);
$box-shadow-dark: rgba($base-black, .2);
$box-shadow-darker: rgba($base-black, .5);
$border-colour: rgba($base-black, .12);
$border-colour-dark: rgba($base-black, .23);
$primary-border-hover: rgba($primary, .8);
$primary-focus: rgba($primary, .4);

/*------------------------------------------------------------------------------
// Font Variables / Bootstrap 5 :root variables
//----------------------------------------------------------------------------*/

$default-font-sans: var(--bs-font-sans-serif);
$default-font-mono: var(--bs-font-monospace);

$primary-font: "proforma",serif;
$primary-font-bold: "proforma",serif;

$secondary-font: "pelago",sans-serif;
$secondary-font-semibold: "pelago",sans-serif;

$font-normal: 400;
$font-semi-bold: 600;
$font-semi-bolder: 700;
$font-bold: 800;

$font-size-lead: 16px;
$font-size-df: 14px;
$font-size-xl: 20px;
$font-size-lg: 18px;
$font-size-md: 15px;
$font-size-sm: 13px;
$font-size-xs: 12px;
$font-size-xxs: 10px;

$line-height-df: 18px;
$line-height-xl: 24px;
$line-height-lg: 22px;
$line-height-md: 20px;
$line-height-sm: 19px;
$line-height-xs: 16px;

/*------------------------------------------------------------------------------
// Icon Variables
//----------------------------------------------------------------------------*/

$icon-size-df: 40px;
$icon-size-xxl: 64px;
$icon-size-xl: 46px;
$icon-size-lg: 36px;
$icon-size-md: 34px;
$icon-size-sm: 28px;
$icon-size-xs: 24px;
$icon-size-xxs: 16px;

/*------------------------------------------------------------------------------
// Layout Variables
//----------------------------------------------------------------------------*/

$m-full-df: 0 0 15px;
$m-full-xl: 0 0 35px;
$m-full-lg: 0 0 30px;
$m-full-md: 0 0 20px;
$m-full-sm: 0 0 10px;
$m-full-xs: 0 0 5px;

$div-p-df: 20px;
$div-p-xl: 40px;
$div-p-lg: 30px;
$div-p-md: 25px;
$div-p-sm: 15px;
$div-p-xs: 5px;

$select-p-df: 15px;
$select-p-xl: 30px;
$select-p-lg: 25px;
$select-p-md: 20px;
$select-p-sm: 10px;
$select-p-xs: 5px;

$link-p-df: 12px;
$link-p-xl: 30px;
$link-p-lg: 20px;
$link-p-md: 15px;
$link-p-sm: 10px;
$link-p-xs: 5px;

$btn-p-df: 15px;
$btn-p-xl: 40px;
$btn-p-lg: 30px;
$btn-p-md: 20px;
$btn-p-sm: 13.6px;
$btn-p-xs: 7px;

$list-m-df: 20px;
$list-m-xl: 30px;
$list-m-lg: 20px;
$list-m-md: 15px;
$list-m-sm: 10px;
$list-m-xs: 5px;

$p-df: 15px;
$p-xxl: 50px;
$p-xl: 40px;
$p-lg: 30px;
$p-md: 20px;
$p-sm: 10px;
$p-xs: 5px;

$m-df: 15px;
$m-xxl: 50px;
$m-xl: 30px;
$m-lg: 25px;
$m-md: 20px;
$m-sm: 10px;
$m-xs: 5px;

$border-radius-df: 0;
$border-radius-xl: 25px;
$border-radius-lg: 20px;
$border-radius-md: 15px;
$border-radius-sm: 10px;
$border-radius-xs: 5px;

$letter-space-df: .05em;
$letter-space-md: 1.2px;
$letter-space-lg: 1.4px;
$letter-space-xl: 1.6px;

/*------------------------------------------------------------------------------
// Breakpoint Variables
//----------------------------------------------------------------------------*/

$viewport-full-max: 1920px;
$viewport-full-min: 400px;
$viewport-xl: 1600px;
$viewport-lg: 1200px;
$viewport-md: 992px;
$viewport-sm: 768px;
$viewport-xs: 576px;

/*------------------------------------------------------------------------------
// z-index Variables
//----------------------------------------------------------------------------*/

$z-index-upper: 40;
$z-index-lower: 1;
$z-index-df: 10;
$z-index-top: 30;
$z-index-middle: 20;
$z-index-bottom: 5;

/*------------------------------------------------------------------------------
// Tag Variables
//----------------------------------------------------------------------------*/

$primary-tag: rgba($primary, .7);
$secondary-tag: rgba($secondary, .7);
$yellow-tag: rgba($warning, .7);
$green-tag: rgba($green, .7);

/*------------------------------------------------------------------------------
// CSS Variables
//----------------------------------------------------------------------------*/

:root {
  --rk-main-bg-colour: #{$base-white};
  --rk-image-overlay-bg: #{$image-overlay-bg};
  --rk-image-overlay-bg-hover: #{$image-overlay-bg-hover};
  --rk-box-shadow: #{$box-shadow};
  --rk-border-colour: #{$border-colour};
  --rk-border-colour-dark: #{$border-colour-dark};
  // Main Colours
  --rk-primary: #{$primary};
  --rk-primary-light: #{$primary-light};
  --rk-primary-lighter: #{$primary-lighter};
  --rk-primary-lightest: #{$primary-lightest};
  --rk-primary-dark: #{$primary-dark};
  --rk-primary-clear: #{$primary-clear};
  --rk-secondary: #{$secondary};
  --rk-secondary-light: #{$secondary-light};
  --rk-secondary-lighter: #{$secondary-lighter};
  --rk-secondary-dark: #{$secondary-dark};
  --rk-default-lighter: #{$default-lighter};
  --rk-default-light: #{$default-light};
  --rk-default-dark: #{$default-dark};
  --rk-default: #{$default};
  --rk-text: #{$text};
  --rk-green: #{$green};
  --rk-mint: #{$mint};
  --rk-grey-lightest: #{$grey-lightest};
  --rk-grey-lighter: #{$grey-lighter};
  --rk-grey-light: #{$grey-light};
  --rk-grey: #{$grey};
  --rk-success: #{$success};
  --rk-success-dark: #{$success-dark};
  --rk-warning: #{$warning};
  --rk-warning-dark: #{$warning-dark};
  --rk-danger: #{$danger};
  --rk-danger-dark: #{$danger-dark};
  --rk-error: #{$error};
  --rk-info: #{$info};
  --rk-base-orange: #{$base-orange};
  --rk-light-purple: #{$light-purple};

  // Black and White
  --rk-base-off-white: #{$base-off-white};
  --rk-base-white: #{$base-white};
  --rk-base-black-light: #{$base-black-light};
  --rk-base-black: #{$base-black};
  --rk-base-grey-lightest: #{$base-grey-lightest};
  --rk-base-grey-lighter: #{$base-grey-lighter};
  --rk-base-grey-light: #{$base-grey-light};
  --rk-base-grey-darker: #{$base-grey-darker};
  --rk-base-grey-dark: #{$base-grey-dark};
  --rk-base-grey: #{$base-grey};

  // Mix Colors
  --rk-primary-mix-darkest: #{$primary-mix-darkest};
  --rk-primary-mix-darker: #{$primary-mix-darker};
  --rk-primary-mix-dark: #{$primary-mix-dark};
  --rk-primary-mix-lightest: #{$primary-mix-lightest};
  --rk-primary-mix-lighter: #{$primary-mix-lighter};
  --rk-primary-mix-light: #{$primary-mix-light};
  --rk-secondary-mix-darkest: #{$secondary-mix-darkest};
  --rk-secondary-mix-darker: #{$secondary-mix-darker};
  --rk-secondary-mix-dark: #{$secondary-mix-dark};
  --rk-secondary-mix-lightest: #{$secondary-mix-lightest};
  --rk-secondary-mix-lighter: #{$secondary-mix-lighter};
  --rk-secondary-mix-light: #{$secondary-mix-light};
  --rk-classic: #{$classic};
  --rk-light: #{$light};
  --rk-dark: #{$dark};
  --rk-shadow-light: #{$shadow-light};
  --rk-shadow: #{$shadow};
  --rk-shadow-base: #{$shadow-base};
  --rk-border-light: #{$border-light};
  --rk-text-shadow: #{$text-shadow};

  // Font Size and Weight
  --rk-default-font-sans: #{$default-font-sans};
  --rk-default-font-mono: #{$default-font-mono};
  --rk-primary-font: #{$primary-font};
  --rk-primary-font-bold: #{$primary-font-bold};
  --rk-secondary-font: #{$secondary-font};
  --rk-secondary-font-semibold: #{$secondary-font-semibold};
  --rk-font-normal: #{$font-normal};
  --rk-font-semi-bold: #{$font-semi-bold};
  --rk-font-semi-bolder: #{$font-semi-bolder};
  --rk-font-bold: #{$font-bold};
  --rk-font-size-df: #{$font-size-df};
  --rk-font-size-xl: #{$font-size-xl};
  --rk-font-size-lg: #{$font-size-lg};
  --rk-font-size-md: #{$font-size-md};
  --rk-font-size-sm: #{$font-size-sm};
  --rk-font-size-xs: #{$font-size-xs};
  --rk-font-size-xxs: #{$font-size-xxs};
  --rk-font-size-lead: #{$font-size-lead};

  // Line Height
  --rk-line-height-df: #{$line-height-df};
  --rk-line-height-xl: #{$line-height-xl};
  --rk-line-height-lg: #{$line-height-lg};
  --rk-line-height-md: #{$line-height-md};
  --rk-line-height-sm: #{$line-height-sm};
  --rk-line-height-xs: #{$line-height-xs};

  // Icon Sizing
  --rk-icon-size-df: #{$icon-size-df};
  --rk-icon-size-xxl: #{$icon-size-xxl};
  --rk-icon-size-xl: #{$icon-size-xl};
  --rk-icon-size-lg: #{$icon-size-lg};
  --rk-icon-size-md: #{$icon-size-md};
  --rk-icon-size-sm: #{$icon-size-sm};
  --rk-icon-size-xs: #{$icon-size-xs};
  --rk-icon-size-xxs: #{$icon-size-xxs};

  // Margin
  --rk-m-full-df: #{$m-full-df};
  --rk-m-full-xl: #{$m-full-xl};
  --rk-m-full-lg: #{$m-full-lg};
  --rk-m-full-md: #{$m-full-md};
  --rk-m-full-sm: #{$m-full-sm};
  --rk-m-full-xs: #{$m-full-xs};

  // Div Padding
  --rk-div-p-df: #{$div-p-df};
  --rk-div-p-xl: #{$div-p-xl};
  --rk-div-p-lg: #{$div-p-lg};
  --rk-div-p-md: #{$div-p-md};
  --rk-div-p-sm: #{$div-p-sm};
  --rk-div-p-xs: #{$div-p-xs};

  // Select Box Padding
  --rk-select-p-df: #{$select-p-df};
  --rk-select-p-xl: #{$select-p-xl};
  --rk-select-p-lg: #{$select-p-lg};
  --rk-select-p-md: #{$select-p-md};
  --rk-select-p-sm: #{$select-p-sm};
  --rk-select-p-xs: #{$select-p-xs};

  // Link Padding
  --rk-link-p-df: #{$link-p-df};
  --rk-link-p-xl: #{$link-p-xl};
  --rk-link-p-lg: #{$link-p-lg};
  --rk-link-p-md: #{$link-p-md};
  --rk-link-p-sm: #{$link-p-sm};
  --rk-link-p-xs: #{$link-p-xs};

  // Button Padding
  --rk-btn-p-df: #{$btn-p-df};
  --rk-btn-p-xl: #{$btn-p-xl};
  --rk-btn-p-lg: #{$btn-p-lg};
  --rk-btn-p-md: #{$btn-p-md};
  --rk-btn-p-sm: #{$btn-p-sm};
  --rk-btn-p-xs: #{$btn-p-xs};

  // List Margin
  --rk-list-m-df: #{$list-m-df};
  --rk-list-m-xl: #{$list-m-xl};
  --rk-list-m-lg: #{$list-m-lg};
  --rk-list-m-md: #{$list-m-md};
  --rk-list-m-sm: #{$list-m-sm};
  --rk-list-m-xs: #{$list-m-xs};

  // Padding & Margin
  --rk-p-df: #{$p-df};
  --rk-p-xxl: #{$p-xxl};
  --rk-p-xl: #{$p-xl};
  --rk-p-lg: #{$p-lg};
  --rk-p-md: #{$p-md};
  --rk-p-sm: #{$p-sm};
  --rk-p-xs: #{$p-xs};
  --rk-m-df: #{$m-df};
  --rk-m-xxl: #{$m-xxl};
  --rk-m-xl: #{$m-xl};
  --rk-m-lg: #{$m-lg};
  --rk-m-md: #{$m-md};
  --rk-m-sm: #{$m-sm};
  --rk-m-xs: #{$m-xs};

  // Border Radius
  --rk-border-radius-df: #{$border-radius-df};
  --rk-border-radius-xl: #{$border-radius-xl};
  --rk-border-radius-lg: #{$border-radius-lg};
  --rk-border-radius-md: #{$border-radius-md};
  --rk-border-radius-sm: #{$border-radius-sm};
  --rk-border-radius-xs: #{$border-radius-xs};

  // Letter Spacing
  --rk-letter-space-df: #{$letter-space-df};
  --rk-letter-space-md: #{$letter-space-md};
  --rk-letter-space-lg: #{$letter-space-lg};
  --rk-letter-space-xl: #{$letter-space-xl};

  // Viewport
  --rk-viewport-full-max: #{$viewport-full-max};
  --rk-viewport-full-min: #{$viewport-full-min};
  --rk-viewport-xl: #{$viewport-xl};
  --rk-viewport-lg: #{$viewport-lg};
  --rk-viewport-md: #{$viewport-md};
  --rk-viewport-sm: #{$viewport-sm};
  --rk-viewport-xs: #{$viewport-xs};

  // Z index
  --rk-z-index-df: #{$z-index-df};
  --rk-z-index-top: #{$z-index-top};
  --rk-z-index-middle: #{$z-index-middle};
  --rk-z-index-bottom: #{$z-index-bottom};

  // Other
  --rk-select-image: url("/templates/zenbasej4template/images/sys-dropdown.svg");
}
