.zen-mega {
  @include element("level-1-wrapper") {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  @include element("level-2") {
    position: absolute;
    top: 110px;
    left: 0;
    right: 0;
    background: var(--rk-base-white);
    z-index: 100;
    border-bottom: 4px solid var(--rk-primary);
  }

  @include element("level-2-inner") {
    padding: 50;
  }

  @include element("level-2-link") {
    padding: 20px 20px 20px 0 !important;
    color: var(--rk-base-black);
    font-size: 14px !important;

    &:hover {
      background: none;
      color: var(--rk-primary);
    }
  }

  @include element("level-2-tab") {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 15px;
  }

  @include element("level-2-inner") {
    padding: 40px 0;
  }

  @include element("level-3-wrapper") {
    column-count: 4;
    margin: 0;
    list-style: none;
    padding: 20px 0;
    border-bottom: 2px solid var(--rk-base-grey-lighter);
  }

  @include element("level-3") {
    border: none;
    background: rgba(0, 0, 0, 0%);
    transition: all .2s ease-in-out;
    padding: var(--rk-p-xs);
    width: 100%;

    &:hover {
      background: var(--rk-base-grey-lighter);
    }

    .zen-mega__link {
      color: var(--rk-base-black);
      text-decoration: none;
      text-transform: none;
      font-size: 16px;
      font-weight: 400;
      width: 100%;
      display: block;
    }
  }
}
