/*==============================================================================
// File:        _breadcrumbs.scss
// Package:     Joomla / Mr Zen
// Synopsis:    Shared breadcrumbs component - SASS
//============================================================================*/

.zen-breadcrumbs {
  font-size: var(--rk-font-size-xs);
  margin: 0 0 var(--rk-m-df);
  font-family: var(--rk-secondary-font);

  /*==============================================================================
  // Modifiers
  //============================================================================*/

  @include modifier("light") {
    background: var(--rk-base-white);
    margin: var(--rk-m-md) 0;
    padding: 0;

    .breadcrumb-item {
      @include adjoin("active") {
        color: var(--rk-text);
      }
    }
  }

  @include media-breakpoint-down(md) {
    display: none;
  }
}

/*------------------------------------------------------------------------------
// Extend button element & modifiers
//----------------------------------------------------------------------------*/

%zen-breadcrumbs {
  @extend .zen-breadcrumbs;
}

%zen-breadcrumbs--light {
  @extend .zen-breadcrumbs--light;
}
