/*==============================================================================
// File:        _rsforms.scss
// Package:     Joomla / Mr Zen
// Synopsis:    RSForms styling & overrides - SASS
//============================================================================*/

.rsform {
  .rsform-select-box,
  .rsform-input-box {
    @extend %zen-input;

    margin: 0 var(--rk-m-md) 0 0;
  }

  .rsfp_thankou_popup_close_btn {
    right: 20px !important;
  }

  .rsform-text-box,
  .form-control {
    border-color: var(--rk-base-grey);
    color: var(--rk-primary);

    &::-webkit-input-placeholder {
      color: var(--rk-primary-light);
    }

    &:-ms-input-placeholder {
      color: var(--rk-primary-light);
    }

    &::placeholder {
      color: var(--rk-primary-light);
    }
  }

  @include modifier("footer") {
    .formContainer .col-md-12 {
      display: flex;

      @include media-breakpoint-down(md) {
        flex-direction: column;
      }
    }

    .rsform-block {
      margin: 0 0 0 var(--rk-m-df);
      position: relative;

      @include media-breakpoint-up(md) {
        width: 100%;
      }
    }

    .form-label {
      background: var(--rk-base-white);
      text-transform: uppercase;
      z-index: var(--rk-z-index-df);
      position: absolute;
      color: var(--rk-secondary);
      font-weight: var(--rk-font-semi-bold);
      line-height: 1em;
      font-size: var(--rk-font-size-xs);
      padding: 0 var(--rk-p-xs);
      left: 15px;
      top: -5px;
    }

    .rsform-input-box {
      border-color: var(--rk-border-colour-dark);
      background: var(--rk-base-white);
      color: var(--rk-secondary);

      &:focus {
        color: var(--rk-secondary);
      }
    }

    .rsform-submit-button {
      width: 100%;
    }
  }
}

.rsform-block-inline {
  display: inline-block;
  vertical-align: top;
}

.rsform-block-title h3 {
  color: var(--rk-text);
  margin: 0;
}

.rsform-submit-button {
  @extend %zen-btn;
  @extend %zen-btn--alternate;
}

.rsform-calendar-button {
  @extend %zen-btn;

  border-bottom-left-radius: 0;
  border: 1px solid var(--rk-primary);
  border-top-left-radius: 0;

  @include adjoin("btn.btn-secondary") {
    @extend %zen-btn;

    border-bottom-left-radius: 0;
    border: 1px solid var(--rk-primary);
    border-top-left-radius: 0;
  }
}

.rsfpStripeContainer {
  text-align: center;
  background: var(--rk-primary-dark);
  padding: var(--rk-p-xxl);
  font-size: var(--rk-font-size-xl);
  margin: var(--rk-m-xxl) auto;
  max-width: 960px;
}

.rsfp_thankou_popup_close_btn {
  right: 20px !important;
}
